import * as React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import Translate from "translate-components";
import eventBus from "../shared/eventBus";

interface TopBarComponentProps {
    onSetShowPanel:(v)=>void,
    showPanel:string,
    goBack?:()=>void
}

const TopBarComponent = ({showPanel,onSetShowPanel}: TopBarComponentProps) => {

      const course = useSelector(
        (state: RootStateOrAny) => state.dashboard.courseSelected
      );
      const countCompletedCourses = course?.courses?.filter((c) => c.isCompleted === true).length;
  return (
    <>
    {/* Top Bar  */}
        <div className="d-flex py-2  justify-content-between align-items-center position-sticky top-0 left-0 w-100 z-1 shadow-1 bkg-background">

            <a onClick={() => eventBus.emit("goBack", "TopBarComponent")} className="btn btn-sm btn-flat-papergray d-flex mx-2 text-primary d-flex">
            <i className="material-icons">west</i>
            &nbsp;
             <Translate>back</Translate>
            </a>

            <div className=' mx-8 text-center'>
                <div className='margin-0 fs-6'>
                    {course.name}
                    &nbsp;|&nbsp;
                    <Translate>youhavecompleted</Translate>&nbsp;{" "}
                    <span>
                    {course && countCompletedCourses
                        ? countCompletedCourses
                        : course.coursesCount}
                    </span>{" "}
                    &nbsp;
                    <Translate>off</Translate>&nbsp;<span>{course.coursesCount}</span>
                    &nbsp;
                    <Translate>lessonsinthiscourse</Translate>
                </div>
            </div>
            <div>
                
                {showPanel!= "colapsed" ? 
                <div></div >
                :
                
                <button className="btn btn-sm btn-flat-papergray d-flex line-lightgray" onClick={()=>{onSetShowPanel("expanded")
                }} >
                    <div className='d-flex align-items-center text-main'>
                    <i className="material-icons text-primary">chevron_left</i> 
                    <span>
                        &nbsp; <Translate>Show_Navigation</Translate>
                    </span>
                </div>
                </button>
                }

            {/* <button className="btn btn-sm btn-flat-papergray d-flex line-lightgray  " onClick={()=>{
                showPanel != "colapsed" ? onSetShowPanel("colapsed") : onSetShowPanel("expanded")
            }}>
                {showPanel!= "colapsed" ? 
                <i className="material-icons right-5 text-primary">chevron_right</i>:
                <div className='d-flex align-items-center text-main'>
                    <i className="material-icons text-primary">chevron_left</i> 
                    <span>
                        &nbsp; <Translate>Show_Navigation</Translate>
                    </span>
                </div>
                }
            </button> */}
            </div>
        </div>
    </>
  );
};

export default TopBarComponent;
