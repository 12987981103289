import axios from 'axios';

const elevenLabsApiKey = 'sk_2587d1e5fa70da396d849e6234aefbe7df937d55be07d805';
const elevenLabsApiUrl = 'https://api.elevenlabs.io/v1/text-to-speech';
const voice ="lj6qGSvYewJTu884EBcQ";

export const fetchSpeech = async (text) =>  {
  try {
    const response = await axios.post(
      elevenLabsApiUrl+"/"+voice,
      {
        text: text
      },
      {
        headers: {
          'xi-api-key': `${elevenLabsApiKey}`,
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer', // Get the audio as binary data
      }
    );

    const audioBlob = new Blob([response.data], { type: 'audio/mp3' });
    return URL.createObjectURL(audioBlob); // Create a URL for the audio file
  } catch (error) {
    console.error('Error fetching speech:', error);
    return null;
  }
};