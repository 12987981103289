import React, { useEffect } from "react";
import Translate from "translate-components";
import EpubViewer from "../../epub-viewer-components/index";

import AudioPlayer from "../../course-audio-player/Index";
import Highlights from "../../epub-viewer-components/Highlights/HighlightsNotesContent";

import * as additionalMediaActions from "../../../data/AdditionalMedia/additionalMediaActions";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import eventBus from "../../shared/eventBus";

const ReaderIntro = ({ course , onCourseCompleted}) => {

  const dispatch = useDispatch();
  const [displayScreen, setDisplayScreen] = React.useState("intro");
  const navigate = useNavigate();
  
  /* ********* Reducer Variables ********* */
  const existAudiobook = () => {
    if (course.additionalMedia !== null) {
      let element = course.additionalMedia.find(
        (element) => element.fkInstituteAdditionalMediaType === 1
      );
      if (element !== undefined) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const handleGoBack = () => {
      if (displayScreen === "intro") navigate("/Dashboard");
      if (displayScreen === "epub") setDisplayScreen("intro");
      if (displayScreen === "audio") setDisplayScreen("intro");
      if (displayScreen === "highlights") setDisplayScreen("intro");
    };
  
    eventBus.on("goBack", handleGoBack);
    return () => {
      eventBus.off("goBack", handleGoBack);
    };
  }, [displayScreen]); // Re-registers the event whenever displayScreen changes

  useEffect(() => {
    if(existAudiobook())
    {
      let element = course.additionalMedia.find(
        (element) => element.fkInstituteAdditionalMediaType === 1
      );
      if (element !== undefined) {
        dispatch(
          additionalMediaActions.saveMediaProperties(
            element.url,
            course.name,
            element.location,
            element.duration
          )
        );
      }
    }
 
  },[course]);

  const IntroComponent =()=>{
    return (
      <div className="courseItemContainer bkg-papergray d-flex justify-content-center align-items-center">
    <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="card rounded-2 p-2">
          <div className="row flex-md-row-reverse align-items-center">
            
            <div className="col-12 col-md-9">
              <h5>
                <Translate>title_reading</Translate>
              </h5>
              <h3>
               {course.name}
              </h3>


              <div className="row">
                <div className="col-12 col-md-4">
                  <a  className="btn btn-accent5 d-flex justify-content-center my-2 flex-wrap" onClick={()=>setDisplayScreen("highlights")}>
                    <span className="material-icons">book</span>&nbsp;
                    <span>
                      <Translate>reading.highligts.highlights</Translate>
                    </span>
                  </a>
                </div>
               {existAudiobook() && <div className="col-12 col-md-4">
                  <a className="btn btn-secondary d-flex justify-content-center my-2 flex-wrap" onClick={()=>setDisplayScreen("audio")}>
                    <span className="material-icons">volume_up</span>&nbsp;
                    <span>
                      <Translate>Listen</Translate>
                    </span>
                  </a>
                </div>}
                <div className="col-12 col-md-4">
                <a  className="btn btn-accent4 d-flex justify-content-center my-2 flex-wrap" onClick={()=>setDisplayScreen("epub")}>
                    <span className="material-icons">auto_stories</span>&nbsp;
                    <span>
                      <Translate>readnow</Translate>
                    </span>
                  </a>
                </div>
              </div>

            </div>

            <div className="col-12 col-md-3">
                <img
                src={course.img}
                  className="img-fluid img-thumbnail "
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>)
  }


  return (
    <>
    {displayScreen == "intro" && <IntroComponent></IntroComponent>}
    {displayScreen == "epub" && <EpubViewer courseId={course.id} onCourseCompleted={onCourseCompleted}></EpubViewer>}
    {displayScreen =="audio" && <AudioPlayer></AudioPlayer>}
    {displayScreen =="highlights" && <Highlights showBackButton={false} courseId={course.id}></Highlights>}
    </>
  )
}
export default ReaderIntro;