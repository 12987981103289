import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Translate from "translate-components";
import  * as landingActions from "../../../data/Landing/landingActions";
import { useNavigate } from "react-router-dom";
import eventBus from "../../shared/eventBus";
interface CourseLinkContentProps {
    course:any,
    courseList:any[],
}

const CourseLinkContent
 = ({course,courseList}: CourseLinkContentProps) => {
 /* ********* Constants ********* */
 const navigate = useNavigate();
 const isLockedStyle = course.isLocked || course.readyToDisplay == false;
 const linkRef = React.useRef<HTMLAnchorElement>(null);
 const userId = useSelector((state: RootStateOrAny) => state.userSession.userId);
  const dispatch = useDispatch();
  
 /* ********* Functions ********* */
 useEffect(() => {
  const handleGoBack = (data) => {
    navigate("/dashboard");
  };

  eventBus.on("goBack", handleGoBack);

  return () => {
    eventBus.off("goBack", handleGoBack);
  };
}, []);

 const prerequisiteText = (c) => {
   if (typeof c == "undefined") return "";
   var type = "";
   var icon = "";
   switch (c.fkInstituteCourseType) {
     case 1:
       type = "course_type_video";
       icon = "ondemand_video";
       break;
     case 2:
       type = "course_type_reading";
       icon = "menu_book";
       break;
     case 3:
       type = "course_type_quiz";
       icon = "fact_check";
       break;
     case 4:
       type = "course_type_discution";
       icon = "supervised_user_circle";
       break;
     case 5:
       type = "course_type_donwload_material";
       icon = "download";
       break;
     default:
       type = "course_type_video";
       icon = "ondemand_video";
       break;
   }
   return (
     <>
       <i className="material-icons">{icon}</i> <Translate>{type}</Translate>
       {"  "}
       <span>{c?.name}</span>
     </>
   );
 };


 const appendRequisites = (previousValue, currentValue, index) => [
   ...previousValue,
   <li key={index}>
     {prerequisiteText(courseList.find((v) => v.id == currentValue))}
   </li>,
 ];
 const prerequisites = String(course.prerequisites)
   .split(",")
   .reduce(appendRequisites, []);

 const readyToDisplay = (v, defaultDisplay) => {
   if (course.readyToDisplay == null || course.readyToDisplay == true)
     return defaultDisplay;
   else
     return (
       <div className="upcoming-icon">
         <i className="material-icons">
           <img height="60px" src={"../../assets/images/ComingSoon.png"}></img>
         </i>
         <Translate>upcoming</Translate>
       </div>
     );
 };
 const setCompletedCourse = (event) => {
       event.preventDefault(); // Optional: Prevent immediate navigation if needed
       //Call to Set The Course as Completed
       dispatch(landingActions.setCourseCompleted(userId, course.id));
       handleProgrammaticClick();
 }
 
 const handleProgrammaticClick = () => {
   if (linkRef.current) {
     linkRef.current.click();
   }
 };

 return (
   <div
     
     className={
       isLockedStyle
         ? "list-item locked" +
           " indentation-" +
           course.indentation +
           " " +
           course.style
         : "list-item" +
           " indentation-" +
           course.indentation +
           " " +
           course.style
     }
   >
    <div className="reading-mark" />
     <div className=' courseItemContainer d-flex justify-content-center align-items-center bkg-papergray'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10'>
            <div className='card'>
          {/* <div className="video-icons" id="video">
          {course.isLocked  ?
            <div className="video-image">  
              <img src={course.img} className="splash" alt="" />
            </div>
          :
            <a href={`${course.href}`} className="video-image" target="_blank">
              <img src={course.img} className="splash" alt="" />
            </a>
            }
          </div> */}
        
        <div className="item-description p-2">
          <h1>{course.name}</h1>
          <hr />
          <div className="course-title d-flex">
            <i className="material-icons">link</i>
            &nbsp;<Translate>link</Translate>
          </div>
          
          <div className="course-description">{course.description}</div>
        </div>

                {course.isLocked ? (
                  <>
                    <div className="lock-info">
                      <div className="lock-title d-flex">
                        <span className="material-icons">info</span>
                        <span>
                          <Translate>require_unlock</Translate>
                        </span>
                      </div>
                      <div className="requirements">
                        <ul>{prerequisites}</ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {course.isCompleted ? (
                      <>
                        <div className="fw-bold fs-4">
                          <div className="completion d-flex align-items-center">
                            <i className="material-icons text-success">
                              check_circle_outline
                            </i>
                            <span className="completion-title">
                              <Translate>completed</Translate>
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="course-completion"></div>
                        <div className="course-completion"></div>
                      </>
                    )}
                  </>
                )}

                {readyToDisplay(
                  course,
                  course.isLocked ? (
                    <div className="access-course d-flex">
                      <i className="material-icons text-warning">lock</i>
                      <span>
                        <Translate>Locked</Translate>
                      </span>
                    </div>
                  ) : (
                    <div className='d-flex justify-content-start'>
                    <>
                      <div  className="btn btn-secondary d-flex left-5 my-2 " onClick={setCompletedCourse} >
                        <span className="material-icons">link</span>
                        &nbsp;
                        <span>
                          <Translate>Link</Translate> {course.name}
                        </span>
                      </div>
                      <a  ref={linkRef} href={`${course.href}`} className=" hidden" target="_blank" rel="noopener noreferrer">
                      </a>
                    </>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
   </div>
 );
};

export default CourseLinkContent;