import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

type AudioPlayerProps = {
  mustCompleteCourse: boolean;
  completeCourse?: () => void;
  videoSource: string;
  playerKey?: string;
};

const AudioPlayer = ({
  mustCompleteCourse,
  completeCourse,
  videoSource,
  playerKey,
}: AudioPlayerProps) => {
    const [playVideo, setPlayVideo] = useState(true);

    /* ********* Effects ********* */
    useEffect(() => {
      if(videoSource)
        setPlayVideo(true);
    }, []);
    
  function onCompleted(): void {
    setPlayVideo(false);
    if (mustCompleteCourse && completeCourse) {
      completeCourse();
    }
    
  }
  return (
    <>
      <div className="video">
        <ReactPlayer
          key={playerKey ?? `${Date.now()}-${Math.random()}`}
          controls={true}
          className="react-player"
          width="640"
          height="360"
          onEnded={onCompleted}
          url={videoSource}
          playing={playVideo}
        />
      </div>
    </>
  );
};

export default AudioPlayer;

