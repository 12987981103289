import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Translate from "translate-components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import eventBus from '../shared/eventBus';
import "../../styles/styles.scss";

/* ********* Actions ********* */
import * as dashboardActions from "../../data/Dashboard/dashBoardActions";
import * as landingActions from "../../data/Landing/landingActions";

/* ********* Components ********* */
import LoadingComponent from "../shared/Loading";
import ELTComponentAuth from "../shared/ELTComponentAuth";

const CourseVideoItem = ({ id , onCourseCompleted}) => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [playVideo, setPlayVideo] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const feedbackDescription = useRef() as React.MutableRefObject<
    HTMLTextAreaElement
  >;

  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const userId = useSelector(
    (state: RootStateOrAny) => state.userSession.userId
  );
  const isLoadingState = useSelector(
    (state: RootStateOrAny) => state.application.isLoading
  );
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const video = useSelector(
    (state: RootStateOrAny) => state.dashboard.courseSelectedVideo
  );
  const feedback = useSelector(
    (state: RootStateOrAny) => state.dashboard.feedback
  );
  const showFeedbackState = useSelector(
    (state: RootStateOrAny) => state.dashboard.showFeedback
  );

  /* ********* Constants ********* */
  const isLoading = video.previewVideoUrl == "undefined";
  const showFeedback = showFeedbackState && videoEnded;

  /* ********* Effects ********* */
  useEffect(() => {
    //If the Users is not longer Signed In
    if (!userSession.isLoggedIn()) {
      navigate("/");
    }
    if(video == null || video.id != id)
    {
      setPlayVideo(true);
      dispatch(
        dashboardActions.getUserModuleCourse(id, userId, lang)
      );
    }
  }, []);

  useEffect(() => {
   const handleGoBack = (data) => {
     navigate("/dashboard");
   };
 
   eventBus.on("goBack", handleGoBack);
 
   return () => {
     eventBus.off("goBack", handleGoBack);
   };
 }, []);
  /* ********* Functions ********* */
  const handleOnEnded = () => {
    //Call to Set The Course as Completed
    setPlayVideo(false);
    dispatch(landingActions.setCourseCompleted(userId, id));
    setVideoEnded(true);
    onCourseCompleted();
  };

  const handleUpdateDescription = () =>
    dispatch(
      dashboardActions.updateFeedbackDescription(
        feedbackDescription.current.value
      )
    );

  const handleUploadFeedback = () =>
    dispatch(
      dashboardActions.uploadFeedback({
        ...feedback,
        fkUserId: userId,
        fkCourseId: id,
      })
    );

  const handleFeedbackDismiss = () =>
    dispatch(dashboardActions.dismissFeedback());

  const updateScore = (pscore) =>
    dispatch(dashboardActions.updateFeedbackScore(pscore));

  return (
      <div key={id} id={" VideoItem-"+id} className="VideoItem">
        {/* <div className="header-spacer" /> */}

        {!isLoading ? (
          <>

            <div className="video">
              <ReactPlayer
                key={video.previewVideoUrl}
                id={video.previewVideoUrl}
                controls={true}
                className="react-player"
                width="640"
                height="360"
                onEnded={handleOnEnded}
                url={video.previewVideoUrl}
                playing={playVideo}
              />
            </div>

            <div className="container ">
              <div className="row">
                <div className="col-12">
                  <div className="videoTitle">
                    <h1>{video.name}</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="video-footer w-100 border-bottom">
                    {showFeedback ? (
                      <div className="video-rating col-12 col-md-8 order-2 order-sm-1 d-flex align-items-center">
                        <div>
                          <Translate>ratethislesson</Translate>&nbsp;
                        </div>
                        <div className="rating rating2">
                          <div
                            title="Give 5 stars"
                            className={feedback.score === 5 ? "selected" : ""}
                            onClick={() => updateScore(5)}
                          >
                            <i className="material-icons">star</i>
                          </div>
                          <div
                            title="Give 4 stars"
                            className={feedback.score >= 4 ? "selected" : ""}
                            onClick={() => updateScore(4)}
                          >
                            <i className="material-icons">star</i>
                          </div>
                          <div
                            title="Give 3 stars"
                            className={feedback.score >= 3 ? "selected" : ""}
                            onClick={() => updateScore(3)}
                          >
                            <i className="material-icons">star</i>
                          </div>
                          <div
                            title="Give 2 stars"
                            className={feedback.score >= 2 ? "selected" : ""}
                            onClick={() => updateScore(2)}
                          >
                            <i className="material-icons">star</i>
                          </div>
                          <div
                            title="Give 1 star"
                            className={feedback.score >= 1 ? "selected" : ""}
                            onClick={() => updateScore(1)}
                          >
                            <i className="material-icons">star</i>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* { videoEnded &&  <div className="col-12 col-md-4 order-1 order-sm-2">
                      <div
                        onClick={() => navigate("/course/"+id)}
                        className="btn btn-success w-100 top-10"
                      >
                        <span>
                          <Translate>gotocourse</Translate>
                        </span>
                      </div>
                    </div>} */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="bottom-section w-100">
                    {showFeedback ? (
                      <div className="lesson-comments">
                        <div className="comments-header">
                          <Translate>commentsoptional</Translate>
                        </div>
                        <textarea
                          ref={feedbackDescription}
                          value={feedback.description}
                          onChange={handleUpdateDescription}
                          className="textarea"
                        />
                        <div className="comment-buttons">
                          <button
                            className="btn btn-flat-accent2"
                            onClick={handleFeedbackDismiss}
                          >
                            <span className="text-accent3">
                              <Translate>dismiss</Translate>
                            </span>
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-secondary"
                            onClick={handleUploadFeedback}
                          >
                            <Translate>submit</Translate>
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
            </div>

            </div>
            
            
            
          </>
        ) : (
          <LoadingComponent isList={false} count={0} />
        )}
      </div>
  );
};

export default CourseVideoItem;
