import * as React from "react";
import Translate from "translate-components";
import CourseItemComponent from "../CourseItem";
import GetPriceByModuleOrGroup from "../../../data/UpfrontPrices/UpfrontPrices";
interface GroupProps {
  group: any;
  showModal: any;
  showCommingSoon: boolean;
}

const renderGroupPrice = (groupId: any) => {
  var price = GetPriceByModuleOrGroup(groupId, false);
  if (price) {
    return (
      <>
        <Translate>Group_All_For_Just</Translate> {price} <sup>USD</sup>
      </>
    );
  }
};


const Group = ({ group, showModal,showCommingSoon }: GroupProps) => {

  //Requerimemnt of David for Leadership coursesw
  const isleadershipBootCamp = group.name === "leadershipBootCamp";
  const getDateWithFormat = (date: string) => {
    const d = new Date(date);
    const month = d.toLocaleString("default", { month: "long" });
    const day = d.getDate();
    const year = d.getFullYear();
    return `${month} ${day} to ${day + 1} , ${year}`;
  };
  return (<div className="row">
<div className="col-12 py-3">
  <div className="landing-Section-Header">
    <div>
      <h1>
        {" "}
        <Translate>{group.name}</Translate>
      </h1>
      <p>
        <Translate>{group.description}</Translate>
      </p>
    </div>
    {process.env.REACT_APP_SHOW_LEADERSHIP_BOOTCAMP_BUY == "true" &&
      group.buyAllgroup == true && (
        <a className="btn btn-success h-auto" href={group.buyUrl}>
          {" "}
          <Translate>Dashboard_Buy_Course</Translate>
        </a>
      )}
  </div>
  {/* This is the Blue bar to reserve seats */}
  {group.reserveSeatDate != null && (
    <div className="w-100 bkg-accent4 text-background p-1 px-4 d-flex justify-content-between align-items-baseline">
      <h1 className="text-background fs-2 fw-bold m-0">Live</h1>
      <p className="fs-5 bottom-0">
        {getDateWithFormat(group.reserveSeatDate)}
      </p>
      <a
        href={group.reserveSeatUrl}
        target="_blank"
        className="btn btn-success"
      >
        <Translate>Dashboard_Reserve_Seat</Translate>
      </a>
    </div>
  )}
  <div className="bkg-lightgray p-3">
    <div className="row learn-more-courses justify-content-center">
      {group.modules.map((m, i) => (
        <CourseItemComponent
          key={i}
          {...m}
          buyAllgroup={group.buyAllgroup}
          showModal={showModal}
          showCommingSoon={showCommingSoon}
          showHover={true}
        ></CourseItemComponent>
      ))}

      <div className="col-8 py-4">
        {process.env.REACT_APP_SHOW_LEADERSHIP_BOOTCAMP_BUY == "true" &&
          group.buyAllgroup == true && (
            <a
              className="btn btn-success h-auto w-100"
              href={group.buyUrl}
            >
              {" "}
              <Translate>Dashboard_Buy_Course</Translate>
            </a>
          )}
      </div>
    </div>
  </div>
</div>
</div>
  )};

  export default Group;