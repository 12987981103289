import React, { useEffect } from "react";
import Translate from "translate-components";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import * as dashboardActions from "../../data/Dashboard/dashBoardActions";
import { useParams, useNavigate } from "react-router-dom";
import ELTComponentAuth from "../shared/ELTComponentAuth";
import { decodeNumber, encodeNumber } from "../../utils/strings";
interface QuizIntroductionProps {

    courseId: number,
    goToPage: any
}

const QuizIntroduction = ({courseId, goToPage}: QuizIntroductionProps) => {

    const dispatch = useDispatch();
    const userSession = useSelector((state: RootStateOrAny) => state.userSession);
    const lang = useSelector(
      (state: RootStateOrAny) => state.application.selectedLanguageCode
    );
    const course = useSelector(
      (state: RootStateOrAny) => state.dashboard.courseSelectedVideo
    );
    //const { courseId } = useParams();
    useEffect(() => {
      dispatch(
        dashboardActions.getUserModuleCourse(
          courseId,
          userSession.userId,
          lang
        )
      );
    }, [courseId,lang]);

  return (
   <>
      <div className="courseItemContainer bkg-papergray d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card p-2">
                <div className="row">
                  <div className="col-12 text-center">
                    <h1 className="p-3 border-bottom">{course.name}</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 min-height-200">
                    <div className="startquiz-image"></div>
                  </div>
                  <div className="col-12 col-md-8">
                    <div className="padding-all-10 text-center">
                      <h1 className="center text-success top-10">
                        <Translate>quiz_welcome</Translate>
                      </h1>
                      <h4 className="text-center">
                        <Translate>quiz_about_start_quiz</Translate>
                      </h4>

                      <h3 className="text-center text-accent4 top-40 bottom-40">
                        {course.name}
                      </h3>

                      <p>
                        <Translate>quiz_take_quiz_many_time</Translate>
                      </p>
                      <p>
                        <Translate>quiz_higuest_achieved_score</Translate>
                      </p>
                      <h4 className="text-center">
                        {/* <Translate>quiz_about_start_quiz</Translate> */}
                        <Translate>yourscore</Translate>: <span>70%</span>
                      </h4>
                      <hr />
                      <div className="row">
                        {/* <div className="col-12 col-sm-6 top-10">
                          <a
                            // href={`/course/${courseId}`}
                            onClick={() => goToPage("introduction")}
                            className="btn btn-primary width-100p"
                          >
                            <Translate>back</Translate>
                          </a>
                        </div> */}
                        <div className="col-12 col-sm-12 top-10">
                          <a
                            onClick={() => goToPage("questionary")}
                            className="btn btn-success width-100p"
                          >
                            <Translate>start_quiz</Translate>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
   </>
  );
};

export default QuizIntroduction;
