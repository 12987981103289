import * as React from 'react';

interface NextCourseComponentProps {
    isCompleted:Boolean;
    goNext:(v)=>void;
}

const NextCourseComponent = ({isCompleted,  goNext}: NextCourseComponentProps) => {

  return (
    isCompleted &&
    <>
    
        <div className="container bottom-50 top-10 ">
            <div className="row">
            <div className="col-12">
                <div className="card rounded-2">
                    <div className="card-body bkg-papergray">
                    <h5 className="card-title">
                        Next Section
                    </h5>
                    <hr />
                    <p className="card-text">
                        You have completed this section of the course. You can now continue with the next section.
                    </p>
                    <div className='d-flex justify-content-end'>
                        <button
                        onClick={()=>goNext(null)}
                        className="btn btn-success d-flex align-items-center"
                        >
                            <span>
                                Continue
                            </span>
                            
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="width-20">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        
    </>
  );
};

export default NextCourseComponent;
