import * as React from 'react';

import CourseItem from './CourseItem';
import { encodeNumber } from "../../utils/strings";
import { useSearchParams } from 'react-router-dom';
import Translate from "translate-components";
import CourseCertificateNavItem from './course-certificate-components/course-certificate-nav-item';
interface ModuleInterface{
  courses:Array<any>,

}
interface CourseNavigationProps {
  moduleData:ModuleInterface,
  course:any,
  setCourse:(v:any)=>void,
  showPanel: string
  onSetShowPanel:(v:string)=>void,
  swithCourse:(c)=>void,
  isCompleted:boolean,
  hasCertificate:boolean
}

const CourseNavigation = ({moduleData,course,setCourse, showPanel, onSetShowPanel, swithCourse, isCompleted, hasCertificate}: CourseNavigationProps) => {
  const coursesList: any[] = moduleData?.courses ?? [];
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    moduleData == undefined ? <></> :
    <div className={"courseNavigationRightBar width-20p height-100p min-width-300 bkg-papergray border-l-lightgray  text-black position-absolute position-md-relative end-0 z-2  shadow-3 " + showPanel}>
      
      <div className='courseNavigationRightBarController'>
          <button className='btn btn-flat-papergray line-lightgray text-main d-flex align-items-center fs-6 shadow-sm' onClick={()=>onSetShowPanel("colapsed")}>
            {/* <i className="material-icons right-10 text-primary">chevron_right</i> */}
            {/* <i className="material-icons right-10 text-primary">chevron_left</i> */}
            {showPanel!= "colapsed" ? <i className="material-icons right-5 text-primary">chevron_right</i>:<i className="material-icons right-10 text-primary">chevron_left</i>}
            <span>
              &nbsp; <Translate>Hide_Navigation</Translate>
            </span>
          </button>
        </div>
      
      <div className={"overflow-y-scroll"}  >
          {coursesList.map((pcourse:any, index:number) =>   
            <CourseItem index={index} course={pcourse} isActive={course?.id==pcourse.id} goToCourse={(c)=>swithCourse(c)}></CourseItem>
          )}   
          { moduleData!= null && isCompleted && hasCertificate && <CourseCertificateNavItem isActive={course== null} goToCourse={(c)=>swithCourse(c)}/> }
          <div className='line-lightgray my-2'></div>
      </div>
    </div>
  );
};

export default CourseNavigation;