import * as React from 'react';
import Translate from "translate-components";
import  * as landingActions from "../../../data/Landing/landingActions";
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import eventBus from '../../shared/eventBus';
import { useNavigate } from "react-router-dom";
interface CourseDownloadMaterialsContentProps {
    course:any,
    courseList:any[],
}

const CourseDownloadMaterialsContent
 = ({course,courseList}: CourseDownloadMaterialsContentProps) => {
 /* ********* Constants ********* */
 const isLockedStyle = course.isLocked || course.readyToDisplay == false;
 const linkRef = React.useRef<HTMLAnchorElement>(null);
 const userId = useSelector((state: RootStateOrAny) => state.userSession.userId);
 const dispatch = useDispatch();
 const navigate = useNavigate();

 useEffect(() => {
  const handleGoBack = (data) => {
    navigate("/dashboard");
  };

  eventBus.on("goBack", handleGoBack);

  return () => {
    eventBus.off("goBack", handleGoBack);
  };
}, []);

 /* ********* Functions ********* */
 const prerequisiteText = (c) => {
   if (typeof c == "undefined") return "";
   var type = "";
   var icon = "";
   switch (c.fkInstituteCourseType) {
     case 1:
       type = "course_type_video";
       icon = "ondemand_video";
       break;
     case 2:
       type = "course_type_reading";
       icon = "menu_book";
       break;
     case 3:
       type = "course_type_quiz";
       icon = "fact_check";
       break;
     case 4:
       type = "course_type_discution";
       icon = "supervised_user_circle";
       break;
     case 5:
       type = "course_type_donwload_material";
       icon = "download";
       break;
     default:
       type = "course_type_video";
       icon = "ondemand_video";
       break;
   }
   return (
     <>
       <i className="material-icons">{icon}</i> <Translate>{type}</Translate>
       {"  "}
       <span>{c?.name}</span>
     </>
   );
 };


 const appendRequisites = (previousValue, currentValue, index) => [
   ...previousValue,
   <li key={index}>
     {prerequisiteText(courseList.find((v) => v.id == currentValue))}
   </li>,
 ];
 const prerequisites = String(course.prerequisites)
   .split(",")
   .reduce(appendRequisites, []);

 const readyToDisplay = (v, defaultDisplay) => {
   if (course.readyToDisplay == null || course.readyToDisplay == true)
     return defaultDisplay;
   else
     return (
       <div className="upcoming-icon">
         <i className="material-icons">
           <img height="60px" src={"../../assets/images/ComingSoon.png"}></img>
         </i>
         <Translate>upcoming</Translate>
       </div>
     );
 };

 const setCompletedCourse = (event) => {
       event.preventDefault(); // Optional: Prevent immediate navigation if needed
       //Call to Set The Course as Completed
       dispatch(landingActions.setCourseCompleted(userId, course.id));
       handleProgrammaticClick();
 }
 
 const handleProgrammaticClick = () => {
   if (linkRef.current) {
     linkRef.current.click();
   }
 };

 return (
   <div
     
     className={
       isLockedStyle
         ? "list-item locked" +
           " indentation-" +
           course.indentation +
           " " +
           course.style
         : "list-item" +
           " indentation-" +
           course.indentation +
           " " +
           course.style
     }
   >
    <div className='courseItemContainer d-flex justify-content-center align-items-center bkg-papergray'>
    <div className='container my-5'>
      <div className='row justify-content-center'>
        <div className="col-12">
          <div className="card rounded-2 p-2">
            <div className="row flex-md-row-reverse align-items-center">
              <div className="col-12">
                <div className="item-description ">
                  
                  <h1>{course.name}</h1>
                  <div className="course-title d-flex text-primary">
                    <i className="material-icons">download</i>
                    &nbsp;<Translate>title_download_materials</Translate>
                  </div>
                  <hr />
                  <div className="course-description">{course.description}</div>

                  {readyToDisplay(
                    course,
                    course.isLocked ? (
                      <div className="access-course">
                        <i className="material-icons text-warning">lock</i>
                        <span>
                          <Translate>Locked</Translate>
                        </span>
                      </div>
                    ) : (
                      <>
                      <div  className="btn btn-sm btn-success mt-4" onClick={setCompletedCourse} >
                        <div className='d-flex'>
                          <span className="material-icons right-2">download</span>
                          <span>
                            <Translate>download</Translate>
                          </span>
                        </div>
                      </div>
                      <a  ref={linkRef} href={`${course.href}`} className=" hidden" target="_blank" rel="noopener noreferrer">
                      </a>
                    </>
                    )
                  )}

                  
                  {course.isLocked ? (
                          <>
                            <div className="lock-info">
                              <div className="lock-title">
                                <span className="material-icons">info</span>
                                <span>
                                  <Translate>require_unlock</Translate>
                                </span>
                              </div>
                              <div className="requirements">
                                <ul>{prerequisites}</ul>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {course.isCompleted ? (
                              <>
                                <div className="course-completion"></div>
                                <div className="course-completion">
                                  <div className="completion">
                                    <i className="material-icons text-success">
                                      check_circle_outline
                                    </i>
                                    <span className="completion-title">
                                      <Translate>completed</Translate>
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="course-completion"></div>
                                <div className="course-completion"></div>
                              </>
                            )}
                          </>
                        )}
                </div>
              </div>
              <div className="col-12 col-md-3">
                {course.isLocked  ?
                  <div className="video-image">  
                    <img src={course.img}
                    className="img-fluid img-thumbnail" alt="" />
                  </div>
                :
                  <>
                    <div  className="video-image" onClick={setCompletedCourse} >
                      {/* <img src={course.img} className="img-fluid img-thumbnail" alt="" /> */}
                    </div>
                    <a  ref={linkRef} href={`${course.href}`} className=" hidden" target="_blank" rel="noopener noreferrer">
                    </a>
                  </>
                  }
              </div>

            </div>
          </div>
        </div>
        
      
      </div>
      
    </div>
    </div>
    
   </div>
 );
};

export default CourseDownloadMaterialsContent;