import React from "react";
import CourseItemComponent from "../CourseItem";
//---------------------Translation
import Translate from "translate-components";
//-----------Components------//
interface GroupProps {
  group: any;
  showModal: any;
  showCommingSoon: boolean;
}
const LeadershipSummitGroup = ({
  group,
  showModal,
  showCommingSoon,
}: GroupProps) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="bkg-main d-flex justify-content-between text-background align-items-strech">
            <div className="row justify-content-between align-items-strech">
              <div className="col-2 d-flex">
                <div className="d-flex align-items-end">
                  <img src="/DIbarraLeadership.png" className=" img-fluid" />
                </div>
              </div>

              <div className="col-8 col-sm-8 col-md-8 col-lg-6 d-flex">
                <div className="text-center height-100p d-flex flex-column justify-content-center align-items-center">
                  <p className="bottom-0 fs-4">
                    <Translate>experienceTraingingFrom</Translate>
                  </p>
                  <h1 className="text-accent5 fs-1">DAVID R. IBARRA</h1>
                  <p className="bottom-0 fs-4">
                    <Translate>distinguishedAuthorSpeaker</Translate>
                  </p>
                </div>
              </div>

              <div className="col-2 d-flex">
                <div className="d-flex align-items-center">
                  <img src="/LiveReady_Book.png" className=" img-fluid" />
                </div>
              </div>
            </div>
          </div>

          <div className="p-2 bkg-accent4 text-background">
            <div className="row">
              <div className="col-12 col-md-4 fs-4 d-flex justify-content-center justify-content-md-start">
                <span>
                  <Translate>2dayLeadershipSummit</Translate>
                </span>
              </div>
              <div className="col-12 col-md-4 text-center fs-4">
                <Translate>March</Translate> 11 &amp; 12 , 2025
              </div>
              <div className="col-12 col-md-4 text-right d-flex justify-content-center justify-content-md-end">
                <a
                  href="https://buy.stripe.com/dR6eWN18xgtB7Re9AP"
                  className="btn btn-success"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Translate>Dashboard_Reserve_Seat</Translate>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="bkg-lightgray p-3">
            <div className="d-flex justify-content-center">
              <h3 className="text-main text-center">
                <Translate>LeadershipSummitContent</Translate>
              </h3>
            </div>
            <div className="row learn-more-courses justify-content-center">
              {group.modules.map((m, i) => (
                <CourseItemComponent
                  key={i}
                  {...m}
                  buyAllgroup={group.buyAllgroup}
                  showModal={showModal}
                  showCommingSoon={showCommingSoon}
                  showHover={false}
                ></CourseItemComponent>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadershipSummitGroup;
