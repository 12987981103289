import React, { useEffect, useState } from "react";


import ELTComponent from "../../shared/ELTComponent";
import HighlightsContentComponent from "./HighlightsNotesContent";
import { useParams } from "react-router-dom";
import { decodeNumber } from "../../../utils/strings";

const HighlightsNotesAccessComponent = () => {
  const { courseId } = useParams();

  return (
    <ELTComponent hideHeaderFooter={true}>
     <HighlightsContentComponent showBackButton={true} courseId={ decodeNumber(courseId)}></HighlightsContentComponent>
    </ELTComponent>
  );
};

export default HighlightsNotesAccessComponent;
