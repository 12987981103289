import React, { useEffect, useState } from "react";
import Translate from "translate-components";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

/* ********* Actions ********* */
import * as quizActions from "../../data/Quiz/quizActions";

/* ********* Components ********* */
import QuestionComponent from "./Question";
import TimeHeaderComponent from "./TimeHeader";
import BackAdvertisementComponent from "./BackAdvertisement";

const Quiz = ({courseId, goToPage}) => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  //const { courseId } = useParams();

  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const quiz = useSelector(
    (state: RootStateOrAny) => state.quiz.instituteQuizPage
  );
  const quizAnswers = useSelector(
    (state: RootStateOrAny) => state.quiz.answerQuestionByQuiz
  );
  const dateTimeQuizToFinish = useSelector(
    (state: RootStateOrAny) =>
      state.quiz.instituteQuizPage?.dateTimeQuizToFinish
  );
  const isLoaded = useSelector((state: RootStateOrAny) => state.quiz.isLoaded);
  const isCompleted = useSelector(
    (state: RootStateOrAny) => state.quiz.isCompleted
  );
  const isBackButton = useSelector(
    (state: RootStateOrAny) => state.quiz.isBackButton
  );
  const disableFinishButton = useSelector(
    (state: RootStateOrAny) => state.quiz.disableFinishButton
  );

  /* ********* Local State ********* */
  const [disabled, setDisabled] = useState(false);
  const [showBackAdvertisement, setShowBackAdvertisement] = useState(false);

  /* ********* Effects ********* */
  useEffect(() => {
    if(courseId!=null)
      loadQuiz();
  }, [courseId]);

  /* ********* Functions ********* */
  const loadQuiz = () => {
    
    const idEmployee = userSession.userId;
    const countQuestions = 10;
    const pageCount = 10;
    const pageNumber = -1;
    const showIsCorrectAnswer = false;

    dispatch(
      quizActions.createQuiz(
        courseId,
        idEmployee,
        lang,
        countQuestions,
        pageCount,
        pageNumber,
        showIsCorrectAnswer
      )
    );
  };

  const saveAndCompleteQuiz = () => {
    setDisabled(true);
    if (quizAnswers.size > 0) {
      dispatch(
        quizActions.saveAndcompleteQuizByQuizId(
          quiz.idInstituteQuizByEmployee,
          quiz.currentPage,
          Array.from(quizAnswers.values())
        )
      );
    } else {
      dispatch(
        quizActions.completeQuizByQuizId(quiz.idInstituteQuizByEmployee)
      );
    }
    goToPage("finish")
  };

  const onTimesEnd = () => {
    setDisabled(true);
    saveAndCompleteQuiz();
  };

  const questionList =
    quiz != null && quiz.instituteQuestionsByQuiz != null
      ? quiz.instituteQuestionsByQuiz.map((iq, i) => {
          return (
            <QuestionComponent
              key={i}
              disabled={disabled}
              count={i + 1}
              {...iq}
            ></QuestionComponent>
          );
        })
      : null;

  return isCompleted && !isBackButton ? (
   goToPage("finish")
  ) : (
    <>
      {disabled ? (
        <Modal
          size="sm"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          show={true}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <Translate>queztionary_computing</Translate>
            </Modal.Title>
          </Modal.Header>
        </Modal>
      ) : null}
      <TimeHeaderComponent
        timeToFinish={dateTimeQuizToFinish}
        isLoaded={isLoaded}
        disabled={disabled}
        onSaveAndCloseQuiz={onTimesEnd}
      ></TimeHeaderComponent>
      <div className="top-10">&nbsp;</div>
      <div className="container">
        {isLoaded ? (
          <>
            <div className="row justify-content-between align-items-end">
              <div className="col-12">
                <h1>{quiz?.title}</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-12 ">
                <div className="card padding-all-10">
                  <h5>
                    <span className="text-success">
                      <Translate>queztionary_directions</Translate>
                    </span>{" "}
                    <Translate>queztionary_multiplechoise</Translate>
                  </h5>
                </div>
              </div>
            </div>
            {questionList}
            <div className="row justify-content-between top-10">
              <div className="col">
                <button
                  className="btn btn-main"
                  onClick={() => setShowBackAdvertisement(true)}
                >
                  <Translate>back</Translate>
                </button>
              </div>
              <div className="col text-right">
                <button
                  disabled={disableFinishButton}
                  className="btn btn-accent4"
                  onClick={saveAndCompleteQuiz}
                >
                  <Translate>finish</Translate>
                </button>
              </div>
            </div>
          </>
        ) : (
          <div>
            <h3>
              <Translate>queztionary_settingup</Translate>
            </h3>
          </div>
        )}
      </div>
      <div className="top-30" />
      <BackAdvertisementComponent
        show={showBackAdvertisement}
        onClose={() => setShowBackAdvertisement(false)}
        courseId={courseId}
      ></BackAdvertisementComponent>
    </>
  );
};

export default Quiz;
