import axios from "axios";
//import querystring from 'querystring';

class AuthenticationServices {
  constructor() {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;
    this.signupUrl = process.env.REACT_APP_SIGNUP_URL;
    this.supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;
  }

  authenticateWithCredentials(userName, password) {
    const tokenUrl = this.tgrApi + "/Users/Authenticate";

    let params = {
      Username: userName,
      Password: password,
    };

    return axios
      .post(`${tokenUrl}`, params, {
        headers: { "Content-Type": "application/json" },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  authenticateWithCASRefresh(refreshtoken) {
    const tokenUrl =
      this.tgrApi + "/Users/AuthenticateByCasRefreshToken/" + refreshtoken;

    return axios
      .post(`${tokenUrl}`, null, {
        headers: { "Content-Type": "application/json" },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  GetUserSoftwareList(idEmployee) {
    const tokenUrl = this.tgrApi + "/Users/GetUserSoftwareList/" + idEmployee;

    return axios
      .get(`${tokenUrl}`, null, {
        headers: { "Content-Type": "application/json" },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  sendRequestDemoEmail(emailForm) {
    const tokenUrl = this.tgrApi + "/Public/SendRequestDemoEmail";

    return axios
      .post(`${tokenUrl}`, emailForm, {
        headers: { "Content-Type": "application/json" },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  //
  resetPassword(userName, lang, supportedSystem) {
    const tokenUrl = this.tgrApi + "/Users/ResetPassword/" + supportedSystem;

    let params = {
      Username: userName,
      Password: "123", // Password cannot be empty, has at least 3 digits, and has to go in the body
      RedirectTo: this.signupUrl,
      lang: lang,
    };

    return axios
      .patch(`${tokenUrl}`, params, {
        headers: { "Content-Type": "application/json" },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  authenticateWithToken(token) {
    const theUrl = this.tgrApi + "/Users/GetMyInfo";

    return axios
      .post(
        `${theUrl}`,
        // header config
        {
          headers: { Authorization: `${this.grantType} ${token}` },
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updatePassword(userId, newPassword, newPasswordConfirmation, token) {
    const theUrl = this.tgrApi + "/Users/UpdatePassword";
    let theUserId = parseInt(userId);

    let params = {
      UserId: theUserId,
      NewPassword: newPassword,
      NewPasswordConfirmation: newPasswordConfirmation,
    };

    return axios
      .post(`${theUrl}`, params, {
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updatePasswordForSeat(userId, newPassword, newPasswordConfirmation, token) {
    const theUrl = this.tgrApi + "/Users/UpdatePasswordForSeat";
    let theUserId = parseInt(userId);

    let params = {
      UserId: theUserId,
      NewPassword: newPassword,
      NewPasswordConfirmation: newPasswordConfirmation,
    };

    return axios
      .post(`${theUrl}`, params, {
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getLisUserCourses(userId, token, lang, courseType) {
    let theUserId = parseInt(userId);

    const theUrl =
      this.tgrApi +
      "/Users/GetLisUserCourses/" +
      this.supportedSystem +
      "/" +
      theUserId +
      "/" +
      lang +
      "/" +
      courseType;

    return axios
      .post(`${theUrl}`, null, {
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getUserModule(idModule, idEmployee, token, lang, courseType) {
    const theUrl =
      this.tgrApi +
      "/Users/GetUserModule/" +
      this.supportedSystem +
      "/" +
      idModule +
      "/" +
      idEmployee +
      "/" +
      lang +
      "/" +
      courseType;
    return axios
      .post(`${theUrl}`, null, {
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getUserModuleFrontEnd(idModule, idEmployee) {
    const theUrl =
      this.tgrApi +
      "/Users/GetUserModuleFronEnd/" +
      this.supportedSystem +
      "/" +
      idModule +
      "/" +
      (idEmployee!= null? idEmployee : 0)
    return axios
      .post(`${theUrl}`, null, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getUserCourse(idCourse, idEmployee, token, lang) {
    const theUrl =
      this.tgrApi +
      `/Users/GetUserCourse/${this.supportedSystem}/${idCourse}/${idEmployee}/${lang}`;

    return axios
      .post(`${theUrl}`, null, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  addFeedback(feedback, token) {
    const theUrl = this.tgrApi + "/Users/AddFeedbackToCourse";

    return axios
      .post(`${theUrl}`, feedback, {
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  authenticateWithTGRToken(token) {
    const tokenEncode = encodeURIComponent(token);
    const eltTokenUrl =
      this.tgrApi + "/users/AuthenticateByToken?token=" + tokenEncode;
    return axios
      .post(
        `${eltTokenUrl}`,
        // the body stringified
        {}, // header config
        {
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          credentials: "same-origin",
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getUserNotJoinedCourses(employeeId, lang, token) {
    const eltTokenUrl =
      this.tgrApi +
      "/users/GetUserNotJoinedCourses/" +
      this.supportedSystem +
      "/" +
      employeeId +
      "/" +
      lang;
      
    return axios
      .get(
        `${eltTokenUrl}`,
        // the body stringified
        {
          mode: "no-cors",
          headers: {
            Authorization: `${this.grantType} ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getLeadersByOrganization(idOrganization, token){
    const eltTokenUrl =
      this.tgrApi +
      "/users/RetrieveLeaderByOrganization/" + idOrganization;

    return axios
      .get(
        `${eltTokenUrl}`,
        {
          mode: "no-cors",
          headers: {
            Authorization: `${this.grantType} ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getAssociatesByOrganization(idOrganization, token){
    const eltTokenUrl =
      this.tgrApi +
      "/users/RetrieveEmployeesByOrganization/" + idOrganization;

    return axios
      .get(
        `${eltTokenUrl}`,
        {
          mode: "no-cors",
          headers: {
            Authorization: `${this.grantType} ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getAssociatesByLeaderAndOrganization(idLeader, idOrganization, token){
    const eltTokenUrl =
      this.tgrApi +
      "/users/RetrieveEmployeesByLeader/" + idOrganization+"/"+idLeader;

    return axios
      .get(
        `${eltTokenUrl}`,
        {
          mode: "no-cors",
          headers: {
            Authorization: `${this.grantType} ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  verifyEmailExist(email, token){

    const eltTokenUrl =
      this.tgrApi +
      "/users/VerifyUserEmailExist/" + email;

    return axios
      .get(
        `${eltTokenUrl}`,
        {
          mode: "no-cors",
          headers: {
            Authorization: `${this.grantType} ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  createUserAssignedInvitation(assignerId, supportedSystem, name, lastName, email, orgs, lang,  token){
  
    const theUrl = this.tgrApi + "/Users/CreateUserAssignedInvitation/" +assignerId+"/"+supportedSystem+"/"+lang;
    const body ={
      AssignerId: assignerId,
      SupportedSystem: supportedSystem,
      Name:name,
      LastName:lastName,
      Email:email,
      Orgs:JSON.stringify(orgs)
    }
    return axios.post(`${theUrl}`, body,
      {
        mode: "no-cors",
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }).catch((error) => {
      return Promise.reject(error);
    });
  }

  acceptInvitation(idSupportedSystem, idInvitation, lang, token){
    const theUrl = this.tgrApi + `/Users/AcceptInvitation/${idSupportedSystem}/${idInvitation}/${lang}`
    return axios.post(`${theUrl}`, null,
      {
        mode: "no-cors",
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }).catch((error) => {
      return Promise.reject(error);
    });
  }

  upsertEmployeesAssignation(idOrg, idLeader, orgList,lang, token){
    const theUrl = this.tgrApi + `/Users/UpsertEmployeesAssignation/${idOrg}/${idLeader}`
    return axios.put(`${theUrl}`, orgList,
      {
        mode: "no-cors",
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }).catch((error) => {
      return Promise.reject(error);
    });
  }
  //UpsertUserOrganizations/{idSupportedSystem}/{idEmployee}
  upsertUserOrganizations(idSupportedSystem,email, orgList,lang,token){
    const theUrl = this.tgrApi + `/Users/UpsertUserOrganizations/${idSupportedSystem}/${email}/${lang}`
    return axios.put(`${theUrl}`, orgList,
      {
        mode: "no-cors",
        headers: {
          Authorization: `${this.grantType} ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }).catch((error) => {
      return Promise.reject(error);
    });
  }
}

export default AuthenticationServices;
