import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useParams } from "react-router-dom";
import Translate from "translate-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import * as actions from "../../../data/Reading/readingActions";
import * as dashboardActions from "../../../data/Dashboard/dashBoardActions";

import CommentModalComponent from ".././CommentModal";
import DeleteAdvertisement from ".././DeleteAdvertisement";



const HighlightsContentComponent = ({showBackButton, courseId}) => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* ********* Reducer Variables ********* */
  const isLoading = useSelector(
    (state: RootStateOrAny) => state.application.isLoading
  );
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const course = useSelector(
    (state: RootStateOrAny) => state.dashboard.courseSelectedVideo
  );
  const highlightsWereSentByEmail = useSelector(
    (state: RootStateOrAny) => state.reading.sendHighlightsByEmail
  );
  const dbHighlights = useSelector(
    (state: RootStateOrAny) => state.reading.highlights
  );
  const [highlights, setHighlights] = useState<Array<any>>([]);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState<any>(false);
  const [selectedHighlightModel, setselectedHighlightModel] = useState<any>(
    null
  );
  const [typeToShow, setTypeToShow] = useState("all");
  const [sincronizeHighlights, setsincronizeHighlights] = useState(false);

  /* ********* Effects ********* */
  useEffect(() => {
    //Get the Information of the Course
    const userId = userSession.userId;
    dispatch(
      dashboardActions.getUserModuleCourse(courseId, userId, lang)
    );
    dispatch(actions.getHighlights(userId,courseId, lang));
  }, []);

  useEffect(() => {
    setHighlights(dbHighlights);
  }, [dbHighlights]);

  useEffect(() => {
    if (!isLoading && isSendingEmail) setIsSendingEmail(false);
  }, [isLoading]);

  useEffect(() => {
    if (sincronizeHighlights) {
      updateHighligts(highlights);
      setsincronizeHighlights(false);
    }
  }, [sincronizeHighlights]);

  /* ********* Functions ********* */
  const deleteHighlight = (pitem) => {
    const result = highlights.filter((v) => v != pitem);
    const mapedHighlihts = result.map((h) => {
      return { ...(h as object) };
    });

    updateHighligts(mapedHighlihts);
    setHighlights(result);
    setselectedHighlightModel(null);
    setShowDelete(false);
  };

  const HightLightItem = (pitem, pkey) => {
    return (
      <div
        key={"highlithItme" + pitem.text + pkey}
        className="highlightLocation align-items-center"
      >
        <div>
          <div className="p-1">
            {/* <span className="p-1 border-right">Green Highlight</span>{" "} */}
            {/* <span className="p-1">location {pitem.location}</span> */}
          </div>
          <div className="p-1">
            <p className="p-1">{pitem.text}</p>
          </div>
          {pitem.comment != null && pitem.comment != "" && (
            <div className="highlightGroup">
              {pitem.comment != null && pitem.comment != "" && (
                <div
                  onClick={() => {
                    setselectedHighlightModel(pitem);
                  }}
                >
                  <span className={"material-icons"}>edit</span>
                </div>
              )}
              <div className="highlightText">
                <p>{pitem.comment}</p>
              </div>
            </div>
          )}
        </div>
        <div className="deleteHighlight">
          <div
            onClick={() => {
              setselectedHighlightModel(pitem);
              setShowDelete(true);
            }}
          >
            <span className="material-icons">close</span>
          </div>
        </div>
      </div>
    );
  };

  const sendHighlightsByEmail = (phighlights) => {
    setIsSendingEmail(true);
    const result = phighlights.map((h) => {
      return { text: h.text, location: h.location, comment: h.comment };
    });
    dispatch(
      actions.sendHighlightsByEmail(
        userSession.userId,
        courseId,
        lang,
        result
      )
    );
  };

  const turnOffHighlights = () => {
    dispatch(actions.setSendHighlightsByEmailOff());
  };

  const onSaveHighlight = (comment) => {
    const newHighlight = selectedHighlightModel;
    const previousExist = highlights.filter(
      (s: any) => s?.location == selectedHighlightModel?.location
    );

    if (previousExist.length == 0) setHighlights([...highlights, newHighlight]);
    else {
      setHighlights([
        ...highlights.filter((h) => h != selectedHighlightModel),
        {
          text: newHighlight.text,
          location: newHighlight.location,
          comment: comment,
        },
      ]);
    }
    setselectedHighlightModel(null);
    setsincronizeHighlights(true);
  };

  const updateHighligts = (phighligts) => {
    const mapedHighliths = phighligts.map((h) => {
      return { text: h.text, location: h.location, comment: h.comment };
    });

    dispatch(
      actions.saveReadingHighlights(
        userSession.userId,
        courseId,
        mapedHighliths,
        lang
      )
    );
  };

  return (
    <>
      <div className="">
        {showBackButton && <div className="optionsContainer">
          <div className="exitBtn" onClick={() => navigate(-1)}>
            <span className="material-icons">arrow_back</span>{" "}
            <Translate>backtodahsboard</Translate>
          </div>
        </div>}
        <div className="container">
          <div className="card top-80 p-4">
            <div className="row align-items-center">
              <div className="col-12 col-md-10">
                <p>
                  <span>
                    <Translate>reading.highligts.instructions</Translate>
                  </span>
                </p>
                <p className="text-uppercase mb-3">
                  <b>
                    <Translate>reading.highligts.yourhighlightsfor</Translate>:
                  </b>
                </p>
                <h3 className="mb-2">{course.name}</h3>
              </div>
              <div className="col-12 col-md-2">
                <img src={course.img} className="img-fluid" />
              </div>
            </div>
            {highlights != null && highlights.length > 0 ? (
              <div className="row top-40 border-bottom">
                <div className="col-12 highlight-menu">
                  <div className="highlight-items">
                    <button
                      className={typeToShow == "all" ? "selected" : ""}
                      onClick={() => setTypeToShow("all")}
                    >
                      <Translate>All</Translate>
                    </button>
                    <button
                      className={typeToShow == "highlight" ? "selected" : ""}
                      onClick={() => setTypeToShow("highlight")}
                    >
                      <Translate>reading.highligts.highlights</Translate>
                    </button>
                    <button
                      className={typeToShow == "notes" ? "selected" : ""}
                      onClick={() => setTypeToShow("notes")}
                    >
                      <Translate>reading.highligts.notes</Translate>
                    </button>
                  </div>
                  {isSendingEmail ? (
                    <Button className="btn">
                      <Translate>reading.highligts.sending</Translate>
                    </Button>
                  ) : typeToShow == "highlight" ? (
                    <Button
                      className="btn-success"
                      onClick={() =>
                        sendHighlightsByEmail(
                          highlights.filter(
                            (h) => h.comment == null || h.comment == ""
                          )
                        )
                      }
                    >
                      <Translate>reading.send.highligts</Translate>
                    </Button>
                  ) : typeToShow == "all" ? (
                    <Button
                      className="btn-success"
                      onClick={() => sendHighlightsByEmail(highlights)}
                    >
                      <Translate>Send</Translate>
                    </Button>
                  ) : (
                    <Button
                      className="btn-success"
                      onClick={() =>
                        sendHighlightsByEmail(
                          highlights.filter(
                            (h) => h.comment != null && h.comment.length > 0
                          )
                        )
                      }
                    >
                      <Translate>reading.send.notes</Translate>
                    </Button>
                  )}
                </div>
                {typeToShow == "highlight"
                  ? highlights
                      .filter((h) => h.comment == null || h.comment == "")
                      .map((h, i) => HightLightItem(h, i))
                  : typeToShow == "all"
                  ? highlights.map((h, i) => HightLightItem(h, i))
                  : highlights
                      .filter((h) => h.comment != null && h.comment.length > 0)
                      .map((h, i) => HightLightItem(h, i))}
              </div>
            ) : (
              <div className="no-courses-message">
                <span>
                  <Translate>reading.highligts.nohighlights</Translate>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {highlightsWereSentByEmail && (
        <>
          <Modal
            centered
            aria-labelledby="contained-modal-title-vcenter"
            onHide={turnOffHighlights}
            show={true}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <Translate>reading.highligts.were.sent</Translate>
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button className="btn-danger" onClick={turnOffHighlights}>
                <Translate>close</Translate>
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <DeleteAdvertisement
        show={showDelete}
        selectedHighlight={selectedHighlightModel}
        onAccept={deleteHighlight}
        onClose={() => {
          setselectedHighlightModel(null);
          setShowDelete(false);
        }}
      ></DeleteAdvertisement>
      <CommentModalComponent
        highlight={selectedHighlightModel}
        show={selectedHighlightModel !== null && showDelete == false}
        onCancel={() => setselectedHighlightModel(null)}
        onSave={onSaveHighlight}
      ></CommentModalComponent>
      </>
  );
};

export default HighlightsContentComponent;
