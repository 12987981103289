import * as types from "../constants/actionTypes";

interface State {
  error: null;
  products: any[];
  coursesList: any[];
  courseSelectedId: number;
  courseSelected: {
    courses: any[]; // Add the 'courses' property with the appropriate type
  };
  courseSelectedVideo: any;
  notJoinedModules: any[];
  wishlist: any[];
  feedback: any;
  showFeedback: boolean;
  isAllowed: boolean;
  organizations: any[];
  leaders: any[];
  associates: any[];
  availableModules: any[];
  assignedModules: any[];
  assignedCoursesProgress: any[];
  isEmailUnique: boolean;
  emailCheckChanged: string;
  newUserInvitations: any[];
  pendingInvitation: any;
  isLoadinInvitation: boolean;
  isLoading: boolean;
  isInvitationAccepted: boolean;
  associatesByOrganization: any[];
  organizationsEmployee: any[];
  
}

const initialState: State = {
  error: null,
  products: [],
  coursesList: [],
  courseSelectedId: 1,
  courseSelected : {} as any,
  courseSelectedVideo: {},
  notJoinedModules: [],
  wishlist: [],
  feedback: {},
  showFeedback: true,
  isAllowed: true,
  organizations:[],
  leaders:[],
  associates:[],
  availableModules:[],
  assignedModules:[],

  //Progress Courses Report
  assignedCoursesProgress:[],
  isEmailUnique:false,
  emailCheckChanged: "",
  newUserInvitations:[],
  pendingInvitation:null,
  isLoadinInvitation: false,
  isLoading: false,
  isInvitationAccepted: false,
  associatesByOrganization:[],
  organizationsEmployee:[]
};

export default function dashBoardReducer(state = initialState, action) {
  let errorMessage = "You've found an unkown glitch! Please contact support.";

  switch (action.type) {
  
    case types.DASHBOARD_GET_LIST_USER_COURSES_REJECTED: {
      if (action.payload === undefined) {
        errorMessage = "The post request is not reaching the server.";
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.message !== undefined
      ) {
        errorMessage = action.payload.data.message;
      }

      return {
        ...state,
        coursesList: [],
        products: [],
        error: errorMessage,
      };
    }

    case types.DASHBOARD_GET_LIST_USER_COURSES_FULFILLED: {
      return {
        ...state,
        products:
          action.payload.data === null ? [] : action.payload.data.products,
        coursesList:
          action.payload.data === null ? [] : action.payload.data.modules,
      };
    }
    case types.DASHBOARD_GET_MODULE_FULFILLED: {
      const { isAllowed, selectedModule } = action.payload.data;

      return {
        ...state,
        courseSelected: selectedModule,
        isAllowed: isAllowed,
      };
    }
    case types.DASHBOARD_GET_MODULE_REJECTED: {
      if (action.payload === undefined) {
        errorMessage = "The post request is not reaching the server.";
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.message !== undefined
      ) {
        errorMessage = action.payload.data.message;
      }

      return {
        ...state,
        courseSelected: {},
        error: errorMessage,
      };
    }
    case types.DASHBOARD_GET_MODULE_COURSE_FULFILLED: {
      return {
        ...state,
        courseSelectedVideo:
          action.payload.data === null
            ? {}
            : action.payload.data.selectedCourse,
        feedback:
          action.payload.data === null ||
          action.payload.data.selectedCourse.feedback === null
            ? { description: "", score: 5 }
            : action.payload.data.selectedCourse.feedback,
      };
    }
    case types.DASHBOARD_GET_MODULE_COURSE_REJECTED: {
      if (action.payload === undefined) {
        errorMessage = "The post request is not reaching the server.";
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.message !== undefined
      ) {
        errorMessage = action.payload.data.message;
      }

      return {
        ...state,
        courseSelectedVideo: {},
        error: errorMessage,
      };
    }
    case types.DASHBOARD_UPDATE_FEEDBACK_SCORE: {
      const feedback =state.feedback as any;
      const newFeedback = {
        score: action.payload !== feedback.score ? action.payload : 0,
        description: feedback.description,
      };
      return {
        ...state,
        feedback: newFeedback,
      };
    }

    case types.DASHBOARD_UPDATE_FEEDBACK_DESCRIPTION: {
      const feedback =state.feedback as any;
      const newFeedback = {
        score: feedback.score,
        description: action.payload,
      };
      return {
        ...state,
        feedback: newFeedback,
      };
    }

    case types.DASHBOARD_ADD_FEEDBACK: {
      return {
        ...state,
        feedback: { score: 0, description: "" },
        showFeedback: false,
      };
    }

    case types.DASHBOARD_DISMISS_FEEDBACK: {
      return {
        ...state,
        showFeedback: false,
      };
    }

    case types.QUIZ_COMPLETE_QUIZ_BY_ID: {
      return {
        ...state
      };
    }

    case types.DASHBOARD_GET_USER_NOT_JOINED_COURSES_FULFILLED: {
      return {
        ...state,
        notJoinedModules: action.payload.data,
      };
    }
    case types.DASHBOARD_GET_USER_WISHLIST_FULFILLED: {
      return {
        ...state,
        wishlist: action.payload.data,
      };
    }
    case types.DASHBOARD_ADD_USER_WISHLIST_FULFILLED: {
      const course = state.notJoinedModules.find(
        (c : any) => c.id == action.payload.data.idModule && c.isInWishlist == false
      );
      if (course) {
        let changedCourse = Object.assign({}, course) as any;
        changedCourse.isInWishlist = true;
        const index = state.notJoinedModules.indexOf(course);
        const newItems = [...state.notJoinedModules];
        newItems.splice(index, 1, changedCourse as never);
        return {
          ...state,
          notJoinedModules: newItems,

          wishlist: [...state.wishlist, changedCourse],
        };
      }

      return {
        ...state,

        wishlist: [...state.wishlist, action.payload.data],
      };
    }

    case types.DASHBOARD_REMOVE_USER_WISHLIST_FULFILLED: {
      const course = state.notJoinedModules.find(
        (c: any) => c.id == action.payload.data.idModule && c.isInWishlist == true
      );
      const filterList = state.wishlist.filter(
        (w: any) => w.id != action.payload.data.idModule
      );
      if (course) {
        let changedCourse = Object.assign({}, course) as any;
        changedCourse.isInWishlist = false;
        const index = state.notJoinedModules.indexOf(course);
        const newItems = [...state.notJoinedModules];
        newItems.splice(index, 1, changedCourse as never) ;
        return {
          ...state,
          notJoinedModules: newItems,
          wishlist: filterList,
        };
      }

      return {
        ...state,
        wishlist: filterList,
      };
    }

    case types.DASHBOARD_GET_ORGANIZATION_BY_ASSIGNER_ID_FULFILLED:{
      const {data} = action.payload;
      return{
        ...state,
        organizations: data
      }
    }
    case types.DASHBOARD_GET_ORGANIZATION_BY_ASSIGNER_ID_REJECTED:{
      return{
        ...state,
        organizations: []
      }
    }

    case types.DASHBOARD_GET_LEADERS_BY_ORGANIZATION_FULFILLED:{
      const {data} = action.payload;
      return{
        ...state,
        leaders: data.result
      }
    }

    case types.DASHBOARD_GET_LEADERS_BY_ORGANIZATION_REJECTED:{
      return{
        ...state,
        leaders: []
      }
    }

    case types.DASHBOARD_ASSOCIATES_BY_LEADER_ID_FULFILLED:{
      const {result} = action.payload;
      return{
        ...state,
        associates: result
      }
    }
    case types.DASHBOARD_ASSOCIATES_BY_LEADER_ID_REJECTED:{
      return{
        ...state,
        associates: []
      }
    }

    case types.DASHBOARD_AVAILABLE_INSTITUTE_MODULE_FULFILLED:{
      return{
        ...state,
        availableModules: action.payload
      }
    }

    case types.DASHBOARD_AVAILABLE_INSTITUTE_MODULE_REJECTED:{
      return{
        ...state,
        availableModules: []
      }
    }

    case types.DASHBOARD_ASSIGNED_INSTITUTE_MODULE_FULFILLED:{
      return{
        ...state,
        assignedModules: action.payload
      }
    }
    
    case types.DASHBOARD_ASSIGNED_INSTITUTE_MODULE_REJECTED:{
      return{
        ...state,
        assignedModules: []
      }
    }

    case types.DASHBOARD_UPSERT_AVAILABLE_COURSES_FULFILLED:{
      return{
        ...state,
      }
    }

    case types.DASHBOARD_UPSERT_AVAILABLE_COURSES_REJECTED:{
      return{
        ...state,
        availableModules:[]
      }
    }

    case types.DASHBOARD_GET_ASSIGNED_PROGRESS_COURSES_FULFILLED:{
      const {data}= action.payload;
      
      return{
        ...state,
        assignedCoursesProgress: data,
        
      }
    }

    case types.DASHBOARD_GET_ASSIGNED_PROGRESS_COURSES_REJECTED:{

      return{
        ...state,
        assignedCoursesProgress: [],
        
      }
    }

    case types.DASHBOARD_GET_VERIFY_USERMAIL_EXIST_FULFILLED:{

      const {data}= action.payload;

      const date = new Date();
      const hours = date.getHours();   
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const mi = date.getMilliseconds();

      return{
        ...state,
        isEmailUnique: data,
        emailCheckChanged: hours + ":" + minutes + ":" + seconds + ":" + mi,
      }

    }

    case types.DASHBOARD_GET_VERIFY_USERMAIL_EXIST_REJECTED:{
      const date = new Date();

      const hours = date.getHours();    
      const minutes = date.getMinutes(); 
      const seconds = date.getSeconds(); 
      const mi = date.getMilliseconds();
      return{
        ...state,
        emailCheckChanged: hours + ":" + minutes + ":" + seconds + ":" + mi,
        isEmailUnique: false
      }
      
    }

    case types.DASHBOARD_GET_NEW_USER_INVITATIONS_FULFILLED:{
      const {data}= action.payload;
      return{
        ...state,
        newUserInvitations: data
      }
    }

    case types.DASHBOARD_GET_NEW_USER_INVITATIONS_REJECTED:{
      return{
        ...state,
        newUserInvitations: []
      }
    }

    case types.DASHBOARD_DELETE_PENDING_INVITATION_FULFILLED:{
      return{
        ...state,
        newUserInvitations: state.newUserInvitations.filter((invitation: any)=> invitation.id != action.payload)
      }
    }

    case types.DASHBOARD_DELETE_PENDING_INVITATION_REJECTED:{
      //No changes, set a message or something
      return{
        ...state
      }
    }

    case types.DASHBOARD_SEND_NEW_USER_INVITATION_FULFILLED:{
      return{
        ...state,
        newUserInvitations: [...state.newUserInvitations, action.payload]
      }
    }

    case types.DASHBOARD_SEND_NEW_USER_INVITATION_REJECTED:{

      //Todo: Set a message or something
      return{
        ...state,
      }

    }

    case types.DASHBOARD_GET_PENDING_INVITATION_BY_ID_FULFILLED:{
      const {data} = action.payload;
      return{
        ...state,
        pendingInvitation: data =="" ? null : data,
        isLoading: false
      }
    }
    case types.DASHBOARD_ASSOCIATES_BY_ORGANIZATION_ID_FULFILLED:{
      const {result} = action.payload;
      return{
        ...state,
        associatesByOrganization: result
      }
    }
    case types.DASHBOARD_ASSOCIATES_BY_ORGANIZATION_ID_REJECTED:{
      return{
        ...state,
        associatesByOrganization: []
      }
    }

    case types.DASHBOARD_GET_PENDING_INVITATION_BY_ID_REJECTED:{
      return{
        ...state,
        pendingInvitation: null
      }
    }

    case types.FLIGHT_STARTED:{
      return{
        ...state,
        isLoading: true,
        isLoadinInvitation: true
      }
    }

    case types.FLIGHT_ARRIVED:{
      return{
        ...state,
        isLoading: false,
        isLoadinInvitation: false
      }
    }
    
    case types.DASHBOARD_ACCEPT_INVITATION_FULFILLED:{
      return{
        ...state,
        isInvitationAccepted: true
      }
    }

    case types.DASHBOARD_ACCEPT_INVITATION_REJECTED:{
      return{
        ...state,
        isInvitationAccepted: false
      }
    }

    case types.DASHBOARD_SAVE_ORGANIZATION_HIERARCHY_FULFILLED:{
      return{
        ...state
      }
    }

    case types.DASHBOARD_GET_ORGANIZATION_USER_JOINED_FULFILLED:{
      const {data}= action.payload;
      return{
        ...state,
        organizationsEmployee: data
      }
    }
    case types.DASHBOARD_GET_ORGANIZATION_USER_JOINED_REJECTED:{
      return{
        ...state,
        organizationsEmployee: []
      }
    }
    case types.DASHBOARD__UPSERAT_USER_ORGANIZATIONS_FULFILLED:{
      return{
        
        ...state,
        organizationsEmployee: [],
        
      }
    }
    case types.DASHBOARD__UPSERAT_USER_ORGANIZATIONS_REJECTED:{
      return{
        ...state
      }
    }
    
    case types.COURSE_SET_COMPLETED: {

      //Perform the update of the Courses Completion
      const updatedCourses = state.courseSelected.courses.map((course) => {
        if (course.id === action.payload) {
          return { ...course, isCompleted: true };
        }
        return course;
      });

      const completedCoursesIds = updatedCourses.filter((course) => course.isCompleted).map((course) => course.id);
      //Update the IsLocked property of the courses
      const courseUpdatedPrerequisites = updatedCourses.map((course) => {
        const IsLocked = course.prerequisites != "" ?
          course.prerequisites.split(',').filter((prerequisite) => !completedCoursesIds.includes(parseInt(prerequisite))).length > 0 :
          false;

        return {
          ...course,
          isLocked: IsLocked
        }
      });
    
      const updatedCourseSelected = {
        ...state.courseSelected,
        courses: courseUpdatedPrerequisites,
      };
    
      return {
        ...state,
        courseSelected: updatedCourseSelected,
      };
    }
    
    case types.DASHBOARD_SET_COURSE_COMPLETED_FULFILLED:{
      const {idCourse} = action.payload;
      if(state.courseSelected && state.courseSelected?.courses){
        const index = state.courseSelected.courses.findIndex(c => c.id == idCourse);
        if(index >= 0){
          let newCourses = [...state.courseSelected.courses];
          newCourses[index].isCompleted = true;
          return {
            ...state,
            courseSelected: {
              ...state.courseSelected,
              courses: newCourses,
            },
          };
        }
      }
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
