import * as React from "react";
import Translate from "translate-components";
import CourseItemComponent from "./CourseItem";
import GetPriceByModuleOrGroup from "../../data/UpfrontPrices/UpfrontPrices";
import LeadershipSummit from "./GroupVariations/LeadershipSummitGroup";
import StandardGroup from "./GroupVariations/StandardGroup";
interface GroupProps {
  group: any;
  showModal: any;
}

const renderGroupPrice = (groupId: any) => {
  var price = GetPriceByModuleOrGroup(groupId, false);
  if (price) {
    return (
      <>
        <Translate>Group_All_For_Just</Translate> {price} <sup>USD</sup>
      </>
    );
  }
};


const Group = ({ group, showModal }: GroupProps) => {

  //Requerimemnt of David for Leadership coursesw
  const showCommingSoon = group.name !== "leadershipBootCamp" ? true : false;
  const isleadershipBootCamp = group.name === "leadershipBootCamp";

  return (
    <>
    {isleadershipBootCamp && <LeadershipSummit group ={group} showModal = {showModal} showCommingSoon={showCommingSoon} />}
    {!isleadershipBootCamp && <StandardGroup group ={group} showModal = {showModal} showCommingSoon={showCommingSoon} />}
 
    </>
  );
};

export default Group;
