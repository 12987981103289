export default{
    "en":{
        "quiz-passed":[
            "Great job! You’ve really mastered this.",
            "Well done! Your hard work paid off.",
            "Excellent work! You’re getting better every time.",
            "Nice going! You’ve passed with flying colors.",
            "Fantastic! Your effort is clearly showing.",
            "You’ve done it! Keep up the great work.",
            "Impressive! You’re really on top of this.",
            "Congratulations! You’ve got this down.",
            "Way to go! You’re making great progress.",
            "Superb work! You’ve definitely got the hang of it.",
            "Outstanding! Your dedication is paying off.",
            "Nice work! You’re clearly on the right track.",
            "You’ve excelled! Keep up the fantastic progress.",
            "Bravo! You’ve really shown what you can do.",
            "Wonderful! You’re making this look easy.",
            "Top-notch effort! You’re clearly committed.",
            "Great success! You’re really moving forward.",
            "Awesome job! You’re definitely improving.",
            "Kudos! Your progress is impressive.",
            "Well-earned success! You’re doing great."
        ],
        "quiz-failed":[
            "Good effort! Let’s aim higher next time.",
            "Not quite there, but don’t give up!",
            "You’re on the right path, keep going.",
            "Don’t worry, practice makes perfect.",
            "You’re learning, and that’s what matters.",
            "Keep at it, you’ll get there with time.",
            "Don’t be discouraged, every step counts.",
            "It’s a tough one, but you’re improving.",
            "Progress takes time, stay focused.",
            "Keep pushing, you’re getting closer.",
            "Almost there! Keep striving for success.",
            "Every try brings you closer to your goal.",
            "Stay determined, you’ll make it.",
            "Learning is a journey, keep moving forward.",
            "Don’t sweat it, you’re building your skills.",
            "Keep practicing, you’re on the right track.",
            "You’re doing well, just keep going.",
            "Don’t give up, your effort will pay off.",
            "You’re improving, keep up the effort.",
            "Stay positive, you’re making progress."
        ]
    },
    "es":{
            "quiz-passed":[
                "¡Gran trabajo! Realmente lo has dominado.",
                "¡Bien hecho! Tu esfuerzo ha dado frutos.",
                "¡Excelente trabajo! Mejoras cada vez más.",
                "¡Buen trabajo! Has pasado con honores.",
                "¡Fantástico! Tu esfuerzo es evidente.",
                "¡Lo has logrado! Sigue así.",
                "¡Impresionante! Estás realmente en lo más alto.",
                "¡Felicidades! Tienes esto bajo control.",
                "¡Sigue así! Estás progresando mucho.",
                "¡Trabajo excelente! Lo has entendido a la perfección.",
                "¡Sobresaliente! Tu dedicación está dando frutos.",
                "¡Buen trabajo! Claramente estás en el camino correcto.",
                "¡Has sobresalido! Sigue con este fantástico progreso.",
                "¡Bravo! Has demostrado de lo que eres capaz.",
                "¡Maravilloso! Lo estás haciendo ver fácil.",
                "¡Esfuerzo de primera! Se nota que estás comprometido.",
                "¡Gran éxito! Estás avanzando realmente bien.",
                "¡Excelente trabajo! Definitivamente estás mejorando.",
                "¡Enhorabuena! Tu progreso es impresionante.",
                "¡Éxito bien merecido! Lo estás haciendo muy bien."
            ],
            "quiz-failed":[
                "¡Buen esfuerzo! Vamos a apuntar más alto la próxima vez.",
                "No llegaste esta vez, pero no te rindas.",
                "Estás en el camino correcto, sigue adelante.",
                "No te preocupes, la práctica hace al maestro.",
                "Estás aprendiendo, y eso es lo que importa.",
                "Sigue intentándolo, llegarás con el tiempo.",
                "No te desanimes, cada paso cuenta.",
                "Es difícil, pero estás mejorando.",
                "El progreso toma tiempo, mantente enfocado.",
                "Sigue empujando, estás cada vez más cerca.",
                "¡Casi lo logras! Sigue esforzándote.",
                "Cada intento te acerca a tu objetivo.",
                "Mantente determinado, lo lograrás.",
                "Aprender es un viaje, sigue avanzando.",
                "No te preocupes, estás desarrollando tus habilidades.",
                "Sigue practicando, estás en el camino correcto.",
                "Lo estás haciendo bien, solo sigue adelante.",
                "No te rindas, tu esfuerzo dará frutos.",
                "Estás mejorando, sigue con el esfuerzo.",
                "Mantén una actitud positiva, estás progresando."
            ]

    }
}