import React, { createRef, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Footer from "../shared/Footer";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Translate from "translate-components";

/* ********* Actions ********* */
import * as dashboardActions from "../../data/Dashboard/dashBoardActions";

/* ********* Components ********* */
import ELTComponent from "../shared/ELTComponent";
import EpubViewer from "../epub-viewer-components";
import CourseDownloadMaterialsContent from "./course-download-materials-components/Content";

import CourseVideoComponent from "../course-video-components/Index";
import QuizComponent from "../quiz-components/Index";
import InformationComponent from "./course-info-components/Content"; 
import CourseLinkComponent from "./course-link-components/Content";

import TopBarComponent from "./TopBarComponent";

import CourseNavigation from "./CourseNavigation";
import { decodeNumber, encodeNumber } from "../../utils/strings";
import { CourseType } from "../../utils/enums/courseType";
import LoadingComponent from "../shared/Loading";
import NextCourseComponent from "../shared/NextCourseComponent";
import CertificatesComponent from "../certificates-components/Index";
import CongratulationsReproducerComponent from "./CongratulationsReproducer";
import ConfettiExplosion from 'react-confetti-explosion'
import CourseDiscussionItemComponent from "./course-discussion-components/Content";
import ReaderIntro from "./course-reading-components/ReaderIntro";
import { getAllModules } from "../../data/Landing/landingActions";
import CourseCertificateComponent from "./course-certificate-components";

const CourseComponent = () => {
    const [state, setState] = useState({
        selectedGrade: "tgrViveListo",
        userId: 0,
        pageIndex: 1,
        pageSize: 2,
        filter: "",
        selectedCategory: 0,
        selectedVersion: 0,
        supportedSystem: process.env.REACT_APP_SUPPORTED_SYSTEM,
      });

    /* ********* Uses ********* */
    const dispatch = useDispatch();
    const { courseId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedCourse,setSelectedCourse] = useState<any>(null);
    const [showPanel, setShowPanel] = useState("expanded");
    const[showNextCourse,setShowNextCourse] = useState(false);
    const queryParams = new URLSearchParams(location.search);

    /* ********* Reducer Variables ********* */
    const userSession = useSelector((state: RootStateOrAny) => state.userSession);
    const userId = useSelector(
      (state: RootStateOrAny) => state.userSession.userId
    );
    const lang = useSelector(
      (state: RootStateOrAny) => state.application.selectedLanguageCode
    );
    const module = useSelector(
      (state: RootStateOrAny) => state.dashboard.courseSelected
    );
    const isAllowed = useSelector(
      (state: RootStateOrAny) => state.dashboard.isAllowed
    );

    const [isSwitching,setIsSwitching] = useState(false);

    const [isSelectedCourseCompleted,setIsSelectedCourseCompleted] = useState(false);
    const [certificatePage,setCertificatePage] = useState(false);
    const [isDownloadCertificatePage,setIsDownloadCertificatePage] = useState(false);
    const [isCompleted,setIsCompleted] = useState(false);

    const [showConfetti, setShowConfetti] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    
  /* ********* Effects *******S** */
  useEffect(() => {
    if (!userSession.isLoggedIn()) {
      navigate("/");
    }
    dispatch(dashboardActions.getUserModule(decodeNumber(courseId), userId, lang,0));
  }, [lang]);

  useEffect(() => {
    if (isAllowed == false) {
      navigate(-1);
    }
  }, [isAllowed]);

  useEffect(() => {
    if (isSwitching) {
      setTimeout(()=>{
        setIsSwitching(false);
      },10);
    }
  }, [isSwitching]);

  useEffect(() => {
  if (selectedCourse && selectedCourse.isCompleted &&  !selectedCourse.isLocked) {
      setIsSelectedCourseCompleted(true);
    }
    else{
      setIsSelectedCourseCompleted(false);

    }

  }, [selectedCourse]);


  //If the secction reached the last content try to get the updated information and see if the user can go to the next course
  const reachedLastContent = () => {
    if (module && module.courses && module.courses.length > 0) {
        dispatch(dashboardActions.getUserModule(decodeNumber(courseId), userId, lang,0));
    }
  }

  useEffect(() => {
    if(selectedCourse!= null)
      setShowNextCourse(selectedCourse.fkInstituteCourseType != CourseType.Quiz);
  }, [selectedCourse]);
  
  const goNextCourse = c=>{
    setIsDownloadCertificatePage(false);
    if(!c){
      if (module && module.courses && module.courses.length > 0) {
      
        const index = module.courses.findIndex(c => c.id == selectedCourse.id);
        if (index < module.courses.length - 1) {
          const c = module.courses[index + 1];
          setSearchParams({["c"]: ""+encodeNumber(c.id)})       
          setIsSwitching(true);
          setSelectedCourse(c);
        
          return;
        }
      }
    }else{
      if(c=="certificate"){
        setIsDownloadCertificatePage(true);
        setSearchParams({["c"]: ""+c})             
        setIsSwitching(true);
        setSelectedCourse(null); 
      }else{
        setSearchParams({["c"]: ""+encodeNumber(c.id)})             
        setIsSwitching(true);
        setSelectedCourse(c); 
      }
       
    }
    
  }

const onCourseCompleted = (showConfetti)=>{
  setIsSelectedCourseCompleted(true);
  dispatch(dashboardActions.setCourseCompleted(decodeNumber(courseId)));
  if(showConfetti)
    setShowConfetti(true);
}

const prerequisiteText = (c) => {
  if (typeof c == "undefined") return "";
  var type = "";
  var icon = "";
  switch (c.fkInstituteCourseType) {
    case 1:
      type = "course_type_video";
      icon = "ondemand_video";
      break;
    case 2 :
      type = "course_type_reading";
      icon = "menu_book";
      break;
    case 3:
      type = "course_type_quiz";
      icon = "fact_check";
      break;
    case 4:
      type = "course_type_discution";
      icon = "supervised_user_circle";
      break;
    case 5:
      type = "course_type_donwload_material";
      icon = "download";
      break;     
    case 6:
      type = "course_type_info";
      icon = "info";
      break;
    case 7:
      type = "course_type_link";
      icon = "link";
      break;  
    case 8:
      type = "course_type_reading";
      icon = "menu_book";
      break;             
    default:
      type = "course_type_video";
      icon = "ondemand_video";
      break;
  }
  return (
    <>
      <i className="material-icons">{icon}</i> <Translate>{type}</Translate>
      {"  "}
      <span>{c?.name}</span>
    </>
  );
};

const appendRequisites = (previousValue, currentValue, index) => [
  ...previousValue,
  <li key={index} className="d-flex">
    {prerequisiteText(module.courses.find((v) => v.id == currentValue))}
  </li>,
];

const prerequisites = ()=>String(selectedCourse.prerequisites)
  .split(",")
  .reduce(appendRequisites, []);
  
const cValue = queryParams.get('c');

useEffect(() => {
    if(module!= null && module.courses  && module.courses.length > 0){
      if((selectedCourse != null )){
        if(cValue!= null && selectedCourse.id != decodeNumber(cValue)){
          const value =decodeNumber(cValue);
          const course = module.courses.find(c=>c.id == value);
          setIsSwitching(true);
          setSelectedCourse(course);
        }
      }else{
        if(cValue == null){
          const lastCompleted = module.courses.slice().reverse().find(item => item.isCompleted);
          if(lastCompleted){
            const index = module.courses.findIndex(c => c.id == lastCompleted.id);
            if( index == module.courses.length -1){
              setIsSwitching(true);
              setSelectedCourse(lastCompleted);
            }else{
              setIsSwitching(true);
              setSelectedCourse(module.courses[index + 1]);
            }
            
          }else{
            setIsSwitching(true);
            setSelectedCourse(module.courses[0]);
          }
        }else{
          const value =decodeNumber(cValue);
          const course = module.courses.find(c=>c.id == value);
            setIsSwitching(true);
            setSelectedCourse(course);
        }
      }
  
    }

  },[cValue, module]);

  useEffect(() => {
    const countCompletedCourses = module?.courses?.filter((c) => c.isCompleted === true).length;
    setIsCompleted(module!= null && module.hasCertificate && module.coursesCount > 0 && module.coursesCount === countCompletedCourses);
  }, [selectedCourse, module, module.coursesCount]);

  return (
    <> 
    <div id="CourseV2">
      <div className="fixed-top height-100p bkg-white">
      <ELTComponent>
        
        <div className="padding-top-100 height-100p d-flex position-relative">
          
          {/* This is the lesson view / video / reading  */}
          <div 
            // className="width-80p overflow-y-scroll"
            className="width-100p overflow-y-scroll position-relative "
          >
           {certificatePage?<CertificatesComponent></CertificatesComponent>:
           <>
           <TopBarComponent onSetShowPanel={setShowPanel} showPanel={showPanel}/>
            {
               isSwitching ?<LoadingComponent></LoadingComponent>:
             /* <!-- Download Materials -->*/
              selectedCourse && selectedCourse.isLocked == false &&              
              <>                
                {selectedCourse.fkInstituteCourseType == CourseType.DownloadMaterials&&<CourseDownloadMaterialsContent course={selectedCourse} courseList={module?.courses}></CourseDownloadMaterialsContent>}
                {selectedCourse.fkInstituteCourseType == CourseType.ReadingMaterial&&<ReaderIntro course={selectedCourse} onCourseCompleted={onCourseCompleted}></ReaderIntro>}
                {selectedCourse.fkInstituteCourseType == CourseType.CourseVideo&&<CourseVideoComponent id={selectedCourse.id}  onCourseCompleted={onCourseCompleted}></CourseVideoComponent>}
                {selectedCourse.fkInstituteCourseType == CourseType.Quiz && <QuizComponent courseId={selectedCourse.id} onCourseCompleted={onCourseCompleted} showNextCourse={v=>setShowNextCourse(v)} ></QuizComponent>}
                {selectedCourse.fkInstituteCourseType == CourseType.Information && <InformationComponent course={selectedCourse} courseList={module?.courses}></InformationComponent>}
                {selectedCourse.fkInstituteCourseType == CourseType.Link && <CourseLinkComponent course={selectedCourse} courseList={module?.courses}></CourseLinkComponent>}
                {selectedCourse.fkInstituteCourseType == CourseType.Discussion && <CourseDiscussionItemComponent course={selectedCourse} courseList={module?.courses}></CourseDiscussionItemComponent>}
                {selectedCourse.fkInstituteCourseType == CourseType.Manual && <ReaderIntro course={selectedCourse} onCourseCompleted={onCourseCompleted} ></ReaderIntro>}
              </>
            }
            {isDownloadCertificatePage && module!= null && module.hasCertificate && <CourseCertificateComponent/>}
            {
              selectedCourse && selectedCourse.isLocked && 
                <div className="lockedModule bkg-papergray">
                  <div className="card p-2">
                    <div className="row d-flex align-items-center flex-md-row-reverse">
                    <div className="col-12 col-md-8">
                        <h1>{selectedCourse.name}</h1>
                        <span>{selectedCourse.description}</span>
                        
                        <div className="d-flex flex-row justify-content-center width-100p bkg-papergray p-2 my-2 fs-3 rounded-3 text-main">
                          <i className="material-icons text-warning fs-1">lock</i>
                          <span>
                            <Translate>Locked</Translate>
                          </span>
                        </div>                  

                        <div className="text-accent4">
                          To unlock this content complete the previous sections
                        </div>
                        <ul>
                          {prerequisites()}
                        </ul>
                      </div>
                      <div className="col-12 col-md-4">
                        <img src={selectedCourse.img} className="w-100 w-md-10 d-hidden d-md-block" alt="" />
                      </div>
                      
                    </div>
                  
                  
                  </div>
              </div>
            }
            {showNextCourse && module  && module.courses && module.courses.length > 1 && (module.courses.indexOf(selectedCourse) < module.courses.length-1) && <NextCourseComponent goNext={goNextCourse} isCompleted={isSelectedCourseCompleted}></NextCourseComponent>}
            </>
            }
            <Footer />
          </div>
          {/* Course Content */}
          
          <CourseNavigation course={selectedCourse} setCourse={v=>setSelectedCourse(v)} moduleData={module} showPanel={showPanel} onSetShowPanel={setShowPanel} swithCourse={goNextCourse} isCompleted={isCompleted} hasCertificate={module.hasCertificate}></CourseNavigation>
          {showConfetti && <ConfettiExplosion  zIndex={99999} onComplete={()=>{
              setShowConfetti(false);

          }}/>}
        </div>
      </ELTComponent>   
      </div>        
    </div>

    </>
  );
};

export default CourseComponent;
