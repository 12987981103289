import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Translate from "translate-components";
import * as actions from "../../../data/Certificates/certificatesActions";

interface CourseCertificateComponentProps {}

const CourseCertificateComponent: React.FC<CourseCertificateComponentProps> = () => {
  const dispatch = useDispatch();

  // State selectors
  const {
    dashboard: { courseSelected: module },
    userSession,
    application: { selectedLanguageCode: lang },
    certificate: { certificateLoading: isLoading },
  } = useSelector((state: any) => state);

  const isCompleted = module?.coursesCount === module?.completedVideos;

  // Event handlers
  const handleDownloadCertificate = () => {
    if (module?.id && userSession) {
      dispatch(actions.downloadCertificate(userSession, module.id, lang));
    }
  };

  return (
  <>
  <div className="courseItemContainer bkg-papergray d-flex justify-content-center align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-7">
                <div className="card rounded-2 p-3">
                  <h1 className=" text-center">
                    🎓 Course Certificate
                  </h1>
                  <hr />
                  {isCompleted ? (
                    <>
                    
                      <p className="text-center fs-4">
                        < Translate>Congrats_Certificate</Translate>
                      </p>
                      {isLoading ? (
                        <div className="d-flex flex-column  justify-content-center align-items-center">
                          <div className="text-accent3">
                            <Translate>Certificate_Downloading</Translate>
                          </div>
                          <div className="loading-icon">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      ) : (
                        <button
                          onClick={handleDownloadCertificate}
                          className="btn btn-success d-flex justify-content-center"
                        >
                          <span className="right-4">
                            <Translate>downloadcerticate</Translate>
                          </span>
                          <i className="material-icons">download</i>
                        </button>
                      )}
                    </>
                  ) : (
                    <p className="text-gray-500 text-center">
                      <Translate>Complete_AllVideos_To_Unlock</Translate>
                    </p>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>

    </>
  );
};

export default CourseCertificateComponent;
