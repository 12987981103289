import React, { useEffect, useRef } from 'react';
import {fetchSpeech} from "../../data/services/elevenLabsServices";
import { useState } from 'react';
interface CongratulationsReproducerComponentProps {

  isPassed: boolean;
  lang: string;
}

 import congratulationsText from "../../data//Static/CongratulationsReproducerData";

const CongratulationsReproducerComponent = ({isPassed,lang}: CongratulationsReproducerComponentProps) => {
  
  const [text, setText] = useState<string|null>(null);
  const [audioUrl, setAudioUrl] = useState<string|null>("");
  const audioRef = useRef<HTMLAudioElement>(null);

  const allTexts = isPassed ? congratulationsText[lang]["quiz-passed"] :  congratulationsText[lang]["quiz-failed"];
  
  // Generate a random index based on the array length
  const randomIndex = Math.floor(Math.random() * allTexts.length);

  const handleSpeakClick = async () => {
    if(text != null)
    {    
        const url = await fetchSpeech(text);
        setAudioUrl(url);
      }
  };

 useEffect (() => {
      if(allTexts!= null && allTexts.length > 0)
       setText(allTexts[randomIndex]);
      
  }, [allTexts]);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  useEffect(() => {
    if (audioUrl) {
      playAudio();
    }
  },[audioUrl]);

  useEffect(() => {
    if(text!= null)
      handleSpeakClick();
  }, [text]);
  
  return (
    <div className='hidden'>
      {audioUrl && (
        <audio controls autoPlay ref={audioRef} >
          <source src={audioUrl} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  );
};

export default CongratulationsReproducerComponent;
