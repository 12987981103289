import React from "react";
import otherTheme from "./other";
import tgrTheme from "./liveReady";
import eltTheme from "./elt";
import stopDriftingTheme from "./stopDrifting";
import dealerUniversityTheme from "./dealerUniversity";
import liveReadyTheme from "./liveReady";
import motosAmericaTheme from "./motosAmerica"
import heritageTheme from "./heritage"

const Theme =
  process.env.REACT_APP_SUPPORTED_SYSTEM == 1
    ? tgrTheme
    : process.env.REACT_APP_SUPPORTED_SYSTEM == 2
    ? stopDriftingTheme
    : process.env.REACT_APP_SUPPORTED_SYSTEM == 3
    ? dealerUniversityTheme
    : process.env.REACT_APP_SUPPORTED_SYSTEM == 5
    ? eltTheme
    : process.env.REACT_APP_SUPPORTED_SYSTEM == 6
    ? liveReadyTheme
    : process.env.REACT_APP_SUPPORTED_SYSTEM == 7
    ? motosAmericaTheme
    : process.env.REACT_APP_SUPPORTED_SYSTEM == 8
    ? heritageTheme
    : otherTheme;
const ThemeContext = React.createContext(Theme);

export { ThemeContext, Theme };
