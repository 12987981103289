import React from "react";
import ContentLoader from "react-content-loader";

const LoadingComponent = ({ isList = false, count = 0 }) => {
  const theItems =
    count < 3 ? (
      <>
        <rect x="0" y="58" rx="2" ry="2" width="14%" height="200" />
        <rect x="15%" y="58" rx="15%" ry="2" width="40%" height="200" />
        <rect x="56%" y="58" rx="15%" ry="2" width="44%" height="200" />

        <rect x="0" y="270" rx="2" ry="2" width="14%" height="200" />
        <rect x="15%" y="270" rx="15%" ry="2" width="40%" height="200" />
        <rect x="56%" y="270" rx="15%" ry="2" width="44%" height="200" />
      </>
    ) : (
      <>
        <rect x="0" y="58" rx="2" ry="2" width="14%" height="200" />
        <rect x="15%" y="58" rx="15%" ry="2" width="40%" height="200" />
        <rect x="56%" y="58" rx="15%" ry="2" width="44%" height="200" />

        <rect x="0" y="270" rx="2" ry="2" width="14%" height="200" />
        <rect x="15%" y="270" rx="15%" ry="2" width="40%" height="200" />
        <rect x="56%" y="270" rx="15%" ry="2" width="44%" height="200" />

        <rect x="0" y="480" rx="2" ry="2" width="14%" height="200" />
        <rect x="15%" y="480" rx="15%" ry="2" width="40%" height="200" />
        <rect x="56%" y="480" rx="15%" ry="2" width="44%" height="200" />
      </>
    );
  return (
    <div className="loading-message">
      {isList == true ? (
        <ContentLoader
          width={"100%"}
          height={234 * count}
          // viewBox="0 0 100% 700"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          {theItems}
        </ContentLoader>
      ) : (
        <>
        <div className="courseItemContainer bkg-papergray d-flex justify-content-center align-items-center py-2">
          <div className="container mt-2">
            <div className="row  justify-content-center">
              <div className="col-12 col-md-6">
                <div className="card p-2">
                  <p>Calculating score, please wait...</p>
                  <div className="loading-icon">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        </>
        
      )}
    </div>
  );
};

export default LoadingComponent;
