import React, { useEffect, useState } from "react";
import Quiz from "./Questionary";
import QuizIntroduction from "./QuizIntroduction";
import QuizFinish from "./QuizFinish";
import Questionary from "./Questionary";
import { useNavigate } from "react-router-dom";
import eventBus from "../shared/eventBus";
import { useDispatch } from "react-redux";
/* ********* Actions ********* */
import * as quizActions from "../../data/Quiz/quizActions";
interface MainQuizComponentProps {
  courseId: number
  onCourseCompleted: (v) => void,
  showNextCourse:any
}

const MainQuizComponent = ({courseId, onCourseCompleted, showNextCourse}: MainQuizComponentProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToPage = (page: string) => {
    setQuizPage(page);
  }

  const [quizPage, setQuizPage] = useState<string>("introduction")

  useEffect(() => {
    showNextCourse(quizPage!="introduction" && quizPage!="questionary")
  },[quizPage]);

  useEffect(() => {
      ///Clear Previous Quiz Data
     dispatch(quizActions.clearQuiz());
  },[courseId]);

  useEffect(() => {
    const handleGoBack = (data) => {
      switch (quizPage) {
        case "introduction":
          navigate("/dashboard");
          break;
        case "questionary":
          setQuizPage("introduction");
          break;
        case "finish":
          setQuizPage("introduction");
          break
      }
    };

    eventBus.on("goBack", handleGoBack);

    return () => {
      eventBus.off("goBack", handleGoBack);
    };
  }, [quizPage]);
  return (
    quizPage == "introduction" ? <QuizIntroduction courseId={courseId}  goToPage={goToPage}></QuizIntroduction> :
    quizPage == "questionary" ? <Questionary courseId={courseId} goToPage={goToPage}></Questionary>
    : <QuizFinish   courseId={courseId} goToPage={goToPage } onCourseCompleted={onCourseCompleted}></QuizFinish>
  );
};

export default MainQuizComponent;
