import * as types from "../constants/actionTypes";
import CoursesServicesInstance from "../services/coursesServices";
import ProductsServicesInstance from "../services/productsServices";
import GroupsServicesInstance from "../services/groupServices";
import AuthServicesInstance from "../services/authenticationServices";

const coursesServices = new CoursesServicesInstance();
const productsServices = new ProductsServicesInstance();
const groupsServices = new GroupsServicesInstance();
const authServices = new AuthServicesInstance();

export function getFeaturedCourses(lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    dispatch({ type: types.LANDING_SET_COURSES_LOADING });
    coursesServices
      .getVideos(lang)
      .then((response) => {
        dispatch({
          type: types.LANDING_GET_FEATURED_COURSES_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.DASHBOARD_GET_LIST_USER_COURSES_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getAllModules(employeeId, lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    dispatch({ type: types.LANDING_SET_COURSES_LOADING });
    coursesServices
      .getFeaturedCourses(employeeId, lang)
      .then((response) => {
        dispatch({
          type: types.LANDING_GET_ALL_MODULES_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.DASHBOARD_GET_LIST_USER_COURSES_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getFrontEndModules(employeeId, moduleId) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authServices
      .getUserModuleFrontEnd(employeeId, moduleId)
      .then((response) => {
        dispatch({
          type: types.LANDING_GET_USER_MODULE_FRONT_END_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.LANDING_GET_USER_MODULE_FRONT_END_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getProductById(id, lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    productsServices
      .getById(id, lang)
      .then((response) => {
        dispatch({
          type: types.LANDING_GET_PRODUCT_BY_ID_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.LANDING_GET_PRODUCT_BY_ID_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getAllProducts(lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    dispatch({ type: types.LANDING_SET_PRODUCTS_LOADING });
    productsServices
      .getAllProducts(lang)
      .then((response) => {
        dispatch({
          type: types.LANDING_GET_ALL_PRODUCTS_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.LANDING_GET_ALL_PRODUCTS_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getUserSoftwareList(idEmployee) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authServices
      .GetUserSoftwareList(idEmployee)
      .then((r) => {
        dispatch({
          type: types.LANDING_GET_SOFTWARE_LIST_FULFILLED,
          payload: r,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((e) => {
        dispatch({
          type: types.LANDING_GET_SOFTWARE_LIST_REJECTED,
          payload: e.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function sendRequestDemoEmail(emailForm, callback) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authServices
      .sendRequestDemoEmail(emailForm)
      .then((r) => {
        dispatch({
          type: types.LANDING_SEND_REQUEST_DEMO_EMAIL_FULFILLED,
          payload: r,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((e) => {
        dispatch({
          type: types.LANDING_SEND_REQUEST_DEMO_EMAIL_REJECTED,
          payload: e.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .finally(callback);
  };
}

export function getAllGroups(lang, employeeId) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    dispatch({ type: types.LANDING_SET_GROUPS_LOADING });
    groupsServices
      .GetBySupportedSystem(lang, employeeId)
      .then((response) => {
        dispatch({
          type: types.LANDING_GET_ALL_GROUPS_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.LANDING_GET_ALL_GROUPS_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
export function getCourseById(pid, employeeId, lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    coursesServices
      .getById(pid, employeeId, lang)
      .then((response) => {
        dispatch({
          type: types.LANDING_GET_COURSE_BY_ID_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.LANDING_GET_COURSE_BY_ID_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
export function getRelatedCourses(lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    coursesServices
      .getVideos(lang)
      .then((response) => {
        dispatch({
          type: types.LANDING_GET_COURSES_RELATED_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.LANDING_GET_COURSES_RELATED_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getCoursesByCategory(idCategory, lang) {
  return function(dispatch) {
    dispatch({ type: types.LANDING_SET_COURSES_LOADING });
    dispatch({ type: types.FLIGHT_STARTED });
    coursesServices
      .getByCategory(idCategory, lang)
      .then((response) => {
        dispatch({
          type: types.LANDING_GET_COURSES_BY_CATEGORY_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.LANDING_GET_COURSES_BY_CATEGORY_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getVideosByModule(idModule, lang) {
  return function(dispatch) {
    dispatch({ type: types.LANDING_SET_COURSES_LOADING });
    dispatch({ type: types.FLIGHT_STARTED });
    coursesServices
      .getVideosByModule(idModule, lang, 1)
      .then((response) => {
        dispatch({
          type: types.LANDING_GET_VIDEOS_BY_MODULEID_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.LANDING_GET_VIDEOS_BY_MODULEID_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function setCourseCompleted(idEmployee, idCourse) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    coursesServices
      .setCourseCompleted(idEmployee, idCourse)
      .then((response) => {
        dispatch({
          type: types.COURSE_SET_COMPLETED,
          payload: idCourse,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.COURSE_SET_COMPLETED_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
