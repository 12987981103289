import React, { useEffect } from "react";
import Translate from "translate-components";
import eventBus from "../../shared/eventBus";
import { useNavigate } from "react-router-dom";

interface CourseDownloadMaterialsContentProps {
    course:any,
    courseList:any[],
}

const CourseInfoContent
 = ({course,courseList}: CourseDownloadMaterialsContentProps) => {

const navigate = useNavigate();

  useEffect(() => {
    const handleGoBack = (data) => {
      navigate("/dashboard");
    };

    eventBus.on("goBack", handleGoBack);

    return () => {
      eventBus.off("goBack", handleGoBack);
    };
  }, []);
 /* ********* Constants ********* */
 const isLockedStyle = course.isLocked || course.readyToDisplay == false;

 /* ********* Functions ********* */
 const prerequisiteText = (c) => {
   if (typeof c == "undefined") return "";
   var type = "";
   var icon = "";
   switch (c.fkInstituteCourseType) {
     case 1:
       type = "course_type_video";
       icon = "ondemand_video";
       break;
     case 2:
       type = "course_type_reading";
       icon = "menu_book";
       break;
     case 3:
       type = "course_type_quiz";
       icon = "fact_check";
       break;
     case 4:
       type = "course_type_discution";
       icon = "supervised_user_circle";
       break;
     case 5:
       type = "course_type_donwload_material";
       icon = "download";
       break;
     default:
       type = "course_type_video";
       icon = "ondemand_video";
       break;
   }
   return (
     <>
       <i className="material-icons">{icon}</i> <Translate>{type}</Translate>
       {"  "}
       <span>{c?.name}</span>
     </>
   );
 };


 const appendRequisites = (previousValue, currentValue, index) => [
   ...previousValue,
   <li key={index}>
     {prerequisiteText(courseList.find((v) => v.id == currentValue))}
   </li>,
 ];
 const prerequisites = String(course.prerequisites)
   .split(",")
   .reduce(appendRequisites, []);

 const readyToDisplay = (v, defaultDisplay) => {
   if (course.readyToDisplay == null || course.readyToDisplay == true)
     return defaultDisplay;
   else
     return (
       <div className="upcoming-icon">
         <i className="material-icons">
           <img height="60px" src={"../../assets/images/ComingSoon.png"}></img>
         </i>
         <Translate>upcoming</Translate>
       </div>
     );
 };

 return (
  <>
  <div className='container my-5 min-vh-100'>
    
    <div className='row justify-content-center'>
      <div className="col-12">
        <div className="card rounded-2 p-2">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-12 col-md-9">
              <div className="item-description ">
                <h1>{course.name}</h1>
                <div className="course-title">
                  <i className="material-icons text-warning">info</i>
                  &nbsp;<Translate>Info</Translate>
                </div>
                <div className="course-description">{course.description}</div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <img src={course.img} className="img-fluid img-thumbnail" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   
   <div
     className={
       isLockedStyle
         ? "list-item locked hidden" +
           " indentation-" +
           course.indentation +
           " " +
           course.style
         : "list-item hidden" +
           " indentation-" +
           course.indentation +
           " " +
           course.style
     }
   >
     <div className="reading-mark" />
     <div className="video-icons" id="video">
     {course.isLocked  ?
       <div className="video-image">  
         <img src={course.img} className="splash" alt="" />
       </div>
     :
       <a href={`${course.href}`} className="video-image" target="_blank">
         <img src={course.img} className="splash" alt="" />
       </a>
       }
     </div>
     <div className="item-description ">
       <h1>{course.name}</h1>
       <div className="course-title">
         <i className="material-icons text-warning">info</i>
         &nbsp;<Translate>Info</Translate>
       </div>
       <div className="course-description">{course.description}</div>
     </div>

     {course.isLocked ? (
       <>
         <div className="lock-info">
           <div className="lock-title">
             <span className="material-icons">info</span>
             <span>
               <Translate>require_unlock</Translate>
             </span>
           </div>
           <div className="requirements">
             <ul>{prerequisites}</ul>
           </div>
         </div>
       </>
     ) : (
       <>
         {course.isCompleted ? (
           <>
             <div className="course-completion"></div>
             <div className="course-completion">
               <div className="completion">
                 <i className="material-icons text-success">
                   check_circle_outline
                 </i>
                 <span className="completion-title">
                   <Translate>completed</Translate>
                 </span>
               </div>
             </div>
           </>
         ) : (
           <>
             <div className="course-completion"></div>
             <div className="course-completion"></div>
           </>
         )}
       </>
     )}

     {readyToDisplay(
       course,
       course.isLocked ? (
         <div className="access-course">
           <i className="material-icons text-warning">lock</i>
           <span>
             <Translate>Locked</Translate>
           </span>
         </div>
       ) : (
         <>
       
             <span className="material-icons">info</span>
             <span>
               {course?.description}
             </span>
         </>
       )
     )}
   </div>
  </>
 );
};

export default CourseInfoContent;