import * as React from 'react';
import Translate from "translate-components";
interface CourseCertificateNavItemProps {
    isActive: boolean,
    goToCourse: (v)=>any
}

const CourseCertificateNavItem = ({isActive, goToCourse}: CourseCertificateNavItemProps) => {
  return (
      <div id={"nvitm-certificate" } className={isActive==true?"with-100p p-2 d-flex fw-light fs-6 border-b-accent-2 cursorPointer bkg-lightgray":"with-100p p-2 d-flex fw-light fs-6 border-b-accent-2 cursorPointer bkg-papergray nav-item"} onClick={()=>goToCourse('certificate')}>
        <div>
          <div className="position-relative block border border-2 border-accent-3 width-25 height-25 right-10 rounded-1 text-success bkg-background">
            <i className="material-icons fs-1.2 right-6 span text-success position-absolute top-10 start-50 translate-middle position-l-0">check</i>
          </div>
        </div>
        <div >
            <i className="material-icons text-success">
            check_circle_outline
            </i>
            <span className="text-uppercase text-success fs-6">
            <Translate>completed</Translate>
            </span>
        </div>
    </div>
  );
};

export default CourseCertificateNavItem;

