import * as React from 'react';
import Translate from "translate-components";

interface CourseItemProps {
    index: number;
    course: any;
    isActive: boolean,
    goToCourse: (v)=>any
}

const CourseItem = ({index, course ,isActive, goToCourse}: CourseItemProps) => {

  const getIcon = (c) => {
    if (typeof c == "undefined")  return {icon:"", type:""};;
    var type = "";
    var icon = "";
    var customClass ="";
    switch (c.fkInstituteCourseType) {
      case 1:
        type = "course_type_video";
        icon = "ondemand_video";
        customClass = "text-accent4"
        break;
      case 2:
        type = "course_type_reading";
        icon = "menu_book";
        customClass = "text-accent5"
        break;
      case 3:
        type = "course_type_quiz";
        icon = "fact_check";
        customClass = "text-accent6"
        break;
      case 4:
        type = "course_type_discution";
        icon = "supervised_user_circle";
        customClass = "text-success"
        break;
      case 5:
        type = "course_type_donwload_material";
        icon = "download";
        customClass = "text-accent5"
        break;
      case 6:
        type = "course_type_info";
        icon = "info";
        customClass = "text-secondary"
        break;
      case 7:
        type = "course_type_link";
        icon = "link";
        customClass = "text-secondary"
        break;  
      case 8:
        type = "course_type_reading";
        icon = "menu_book";
        customClass = "text-accent5"
        break;            
      default:
        type = "course_type_video";
        icon = "ondemand_video";
        break;
    }
    return {icon, type, customClass};
  };

 const {icon, type, customClass}= getIcon(course);
  
  return (
    course.indentation === 0?
    <div id={"nvitm-" + index}  onClick={()=>goToCourse(course)}>
      
      <div className='p-2 bottom-0 top-0 border-b-accent3 border-t-accent3'>{course.name}</div>

      <div className={isActive==true?"with-100p p-2 d-flex fw-light fs-6 border-b-accent-2 cursorPointer bkg-lightgray":"with-100p p-2 d-flex fw-light fs-6 border-b-accent-2 cursorPointer bkg-papergray nav-item"}
      // className={"with-100p bkg-accent2 fw-medium text-main p-2 border-t-accent3"}
      >
        <div className={isActive==true ?"with-100p d-flex fw-light fs-6 cursorPointer bkg-lightgray":"with-100p d-flex fw-light fs-6 cursorPointer bkg-papergray nav-item"}>
          <div>
            <div className="position-relative block border border-2 border-accent-3 width-25 height-25 right-10 rounded-1 text-success bkg-background">
              {course.isCompleted && 
                <i className="material-icons fs-1.2 right-6 span text-success position-absolute top-10 start-50 translate-middle position-l-0">check</i>
              }
            </div>
          </div>
          <div>
            {course.name}
            <div className="d-flex " >
              <i className={customClass + ' material-icons right-4'}>{icon}</i>
              <div>
                <Translate>{type}</Translate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>:
      <div id={"nvitm-" + index} className={isActive==true?"with-100p p-2 d-flex fw-light fs-6 border-b-accent-2 cursorPointer bkg-lightgray":"with-100p p-2 d-flex fw-light fs-6 border-b-accent-2 cursorPointer bkg-papergray nav-item"} onClick={()=>goToCourse(course)}>
        <div>
          <div className="position-relative block border border-2 border-accent-3 width-25 height-25 right-10 rounded-1 text-success bkg-background">
            {course.isCompleted && 
            <i className="material-icons fs-1.2 right-6 span text-success position-absolute top-10 start-50 translate-middle position-l-0">check</i>
            }
          </div>
        </div>
        <div >
          {course.name}
          <div className="d-flex " >
          <i className={'material-icons right-4 '+ customClass}>{icon}</i>
          <div>
            <Translate>{type}</Translate>
            </div>
          </div>
        </div>
    </div>
  );
};

export default CourseItem;
