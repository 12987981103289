import React, { useEffect, useState } from "react";
import Translate from "translate-components";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

/* ********* Actions ********* */
import * as landingActions from "../../data/Landing/landingActions";
import * as quizActions from "../../data/Quiz/quizActions";
import * as dashboardActions from "../../data/Dashboard/dashBoardActions";
import CongratulationsReproducerComponent from "../course-components/CongratulationsReproducer";
import { encodeNumber } from "../../utils/strings";
import LoadingComponent from "../shared/Loading";
/* ********* Components ********* */

const QuizFinish = ({courseId, goToPage, onCourseCompleted}) => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const instituteQuizResults = useSelector(
    (state: RootStateOrAny) => state.quiz.instituteQuizResults
  );
  const quiz = useSelector(
    (state: RootStateOrAny) => state.quiz.instituteQuizPage
  );
  const language = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const [reviewQuiz, setReviewQuiz] = useState([]);

  const starsToShow = Math.round(
    instituteQuizResults != null ? (instituteQuizResults?.score / 100) * 5 : 0
  ); //instituteQuizResults?.score

  const starsMissing = 5 - starsToShow;
  //const starsElements = [...Array(starsToShow).keys()]; //new Array(starsToShow);
  const starsElements = Object.keys(starsToShow); //new Array(starsToShow);
  //const starsToRefill = [...Array(starsMissing).keys()]; // new Array(starsMissing);
  const starsToRefill = Object.keys(starsMissing); // new Array(starsMissing);
  const minScore = 70;

  //const lang = useSelector(state => state.application.selectedLanguageCode);

  const [isLoading, setIsLoading] = useState(true);

  const completeItem = () => {
    if (instituteQuizResults?.score >= minScore) {
      const employeeId = userSession.userId;
      if (employeeId > 0 && courseId != "")
      {
        dispatch(
          landingActions.setCourseCompleted(employeeId, courseId)
        );
        onCourseCompleted(instituteQuizResults?.score >= minScore);
      }
    }
  };
  const course = useSelector(
    (state: RootStateOrAny) => state.dashboard.courseSelectedVideo
  );

  const retakeQuiz = () => {
    dispatch(quizActions.quizRetakeQuizWhenFinish());
    goToPage('introduction')
  }
  useEffect(() => {
    if(instituteQuizResults && instituteQuizResults.id > 0){
      setIsLoading(true);
      const pageCount = 10;
      const pageNumber = -1;
      const showIsCorrectAnswer = true;
      
      dispatch(
        quizActions.retrieveQuizByEmployeeByQuizId(
          instituteQuizResults.id,
          pageCount,
          pageNumber,
          language,
          showIsCorrectAnswer,
          () => {
            setIsLoading(false);
          }
        )
      );
      setReviewQuiz([]);
    }
  }, [instituteQuizResults]);

  useEffect(() => {
    if (quiz != null) {
      setReviewQuiz(
        quiz.instituteQuestionsByQuiz
          .filter((item) => item.isCorrectAnswer === false)
          // .filter(item => item.instituteQuizQuestion.hints !== null)
          .map((item) => {
            return {
              description: item.instituteQuizQuestion.description,
              hints:
                item.instituteQuizQuestion.hints != null
                  ? item.instituteQuizQuestion.hints
                  : "",
            };
          })
      );
    }
  }, [quiz]);

  useEffect(() => {
    //Set Complete The Quiz Completion
    completeItem();
  }, [, instituteQuizResults, quiz]);

  useEffect(() => {
    dispatch(
      dashboardActions.getUserModuleCourse(
        courseId,
        userSession.userId,
        lang
      )
    );
  }, lang);

  return (!isLoading ? <>
      <div className="courseItemContainer bkg-papergray d-flex justify-content-center align-items-center py-2">
        <div className="container mt-2">
        <div className="row z-0 ">
          <div className="col-12">
            <div className="card p-2">
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="p-3 border-bottom">{quiz?.title}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 min-height-200">
                  <div className="congratulations-image"></div>
                  &nbsp;
                </div>
                <div className="col-12 col-md-8">
                  
                  <div className="text-center">
                    {instituteQuizResults?.score < minScore ? (
                      <>
                        <h1 className="center text-warning top-10">
                          <Translate>quiz_finish_tryagin</Translate>
                        </h1>
                        <p className="text-center">
                          <Translate>quiz_finish_dont_worry</Translate>
                        </p>
                      </>
                    ) : (
                      <>
                        <h1 className="center text-success top-10">
                          <Translate>quiz_finish_congrats</Translate>
                        </h1>
                        <p className="text-center">
                          <Translate>quiz_finish_complete_the_course</Translate>
                        </p>
                      </>
                    )}

                    <h5 className="text-center top-40 bottom-20">
                      <Translate>quiz_finish_your_score</Translate>
                    </h5>
                    <h2 className="text-center score-rate">
                      {instituteQuizResults?.score}%
                    </h2>
                    <div className="d-inline-block padding-all-10">
                      <div className="scorre-stars">
                        {starsElements.map((s, i) => {
                          return (
                            <i key={i} className="material-icons">
                              star
                            </i>
                          );
                        })}
                        {starsToRefill.map((s, i) => {
                          return (
                            <i key={i} className="material-icons">
                              star_border
                            </i>
                          );
                        })}
                      </div>
                    </div>
                    <p className="top-20">
                      <Translate>quiz_take_quiz_many_time</Translate>
                    </p>
                    <p className="mt-0">
                      <Translate>quiz_higuest_achieved_score</Translate>
                    </p>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-12 col-md-12 mb-2">
                      <a
                        onClick={()=>retakeQuiz()}
                        className="btn btn-accent4 w-100"
                      >
                        <Translate>quiz_finish_retake</Translate>
                      </a>
                    </div>
                    {/* <div className="col-12">
                      <a
                        href={`/course/${
                          course != null
                            ? encodeNumber(course.fkInstituteModule)
                            : courseId
                        }#${course != null ? encodeNumber(course.id) : ""}`}
                        className="btn btn-success w-100"
                      >
                        <Translate>gotocourse</Translate>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {reviewQuiz.length === 0 ? null : (
              <div className="card p-2 top-30">
                <div className="row">
                  <div className="col-12 text-center">
                    <h1 className="p-3 border-bottom">
                      <Translate>review_quiz</Translate>
                    </h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="review-quiz">
                      <tr>
                        <th className="text-center">Answer</th>
                        <th>Question</th>
                        <th>
                          <span className="material-icons text-success mr-1 align-middle">
                            lightbulb
                          </span>
                          <span>Hint</span>
                        </th>
                      </tr>
                      {reviewQuiz.map((item: any, index) => {
                        return (
                          <tr key={index} id={"table_" + index}>
                            <td className="text-center">
                              <span className="material-icons text-warning">
                                cancel
                              </span>
                            </td>
                            <td>{item.description}</td>
                            <td>{item.hints}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
          <CongratulationsReproducerComponent lang={lang} isPassed={instituteQuizResults?.score >= minScore}></CongratulationsReproducerComponent> 
        </div>
        </div>
      </>
      :
      <LoadingComponent></LoadingComponent>
  )
};

export default QuizFinish;
