import axios from "axios";
//import querystring from 'querystring';

class CoursesServices {
  constructor(tokenWT) {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;
    this.supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;
    this.abortController = null;
    if (tokenWT === undefined) {
      this.token = localStorage.getItem("token");
    } else {
      this.tokenWT = tokenWT;
    }

    this.axiosClient = axios.create({
      baseURL: this.tgrApi,
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
      },
      credentials: "same-origin",
    });
  }

  getFeaturedCourses(employeeId, lang) {
    lang = lang == "" ? "en" : lang;
    const theUrl =
      this.tgrApi +
      "/InstituteModule/GetAll/" +
      this.supportedSystem +
      "/" +
      lang +
      "/" +
      employeeId;

    return axios.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }
  getById(id, employeeid, lang) {
    const theUrl =
      this.tgrApi +
      "/InstituteModule/GetModuleById/" +
      this.supportedSystem +
      "/" +
      employeeid +
      "/" +
      id +
      "/" +
      lang;

    return axios.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }
  getRelatedCourses() {
    const theUrl =
      this.tgrApi + "/InstituteModule/GetRelated/" + this.supportedSystem;

    return axios.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  getByCategory(idCategory, lang) {
    const theUrl =
      this.tgrApi + "/InstituteModule/GetByProduct/" + idCategory + "/" + lang;
    return axios.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  getVideosByModule(idModule, lang, courseType) {
    const theUrl =
      this.tgrApi +
      "/InstituteCourse/GetByModuleId/" +
      idModule +
      "/" +
      lang +
      "/" +
      courseType;
    return axios.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  getVideos(lang) {
    const theUrl =
      this.tgrApi + `/InstituteCourse/GetAll/${this.supportedSystem}/${lang}`;
    return axios.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  setCourseCompleted(idEmployee, idCourse) {
    // Cancel the previous request if it exists
    if (this.abortController) {
      this.abortController.abort();
    }
  
    // Create a new AbortController
    this.abortController = new AbortController();
  
    const theUrl =
      this.tgrApi +
      "/InstituteCourse/SetCourseCompleted/" +
      idEmployee +
      "/" +
      idCourse;
  
    return this.axiosClient.post(theUrl, {
      signal: this.abortController.signal, // Attach the signal
    }).catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        return Promise.reject(error);
      }
    });
  }

  getAvaibleInstituteModuleByLeader(idEmployee) {

    const theUrl =
      this.tgrApi +
      "/InstituteModule/RetrieveAvaibleInstituteModuleByLeader/" +
      this.supportedSystem  +
      "/" +
      idEmployee;

    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }
  getAvaibleInstituteModuleByOrganization(idOrganization) {

    const theUrl =
      this.tgrApi +
      "/InstituteModule/RetrieveInstituteModulesAvailableByOrganizations/" +idOrganization;

    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }
  getAssignedInstituteModuleByLeader(idEmployee, idAssociate) {

    const theUrl =
      this.tgrApi +
      "/InstituteModule/RetrieveAssignedInstituteModuleByLeader/" +
      idEmployee+"/" +idAssociate;
    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  upsertAvailableCourses( idSupportedSystem,  idLeader,  associate,  newCourses,lang){
    const theUrl =
    this.tgrApi +
    "/CourseAssignation/UpsertAvailableCourses/" +idSupportedSystem+"/" +idLeader+"/"+associate+"/"+lang;
  return this.axiosClient.put(`${theUrl}`,newCourses).catch((error) => {
    return Promise.reject(error);
  });
  }
}

export default CoursesServices;
